import React from 'react';
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import {CheckboxBootstrap} from "../form/CheckBox";
import TextArea from "../form/TextArea";
import LoadingButton from "../form/LoadingButton";
import * as Yup from "yup";

const VoucherFormForm = ({voucherFormConfig, onUpdate}) => {

    const {consent, confirmEmail, consentMarkup, buttonText, headerMarkup, domains} = voucherFormConfig;

    const validationSchemaProfile = Yup.object().shape({
        buttonText: Yup.string()
            .required('This field is required.')
    });


    const handleSubmit = (form) => {
        const voucherFormConfig = {
            consent: form.consent,
            confirmEmail: form.confirmEmail,
            buttonText: form.buttonText,
            consentMarkup: form.consentMarkup,
            headerMarkup: form.headerMarkup,
            domains: form.domains
        };
        onUpdate(voucherFormConfig)
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchemaProfile}
                initialValues={{
                    consent: consent,
                    confirmEmail: confirmEmail,
                    buttonText: buttonText,
                    consentMarkup: consentMarkup,
                    headerMarkup: headerMarkup,
                    domains: domains,
                }}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>
                        <div>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <h5>Layout</h5>
                                </div>
                                <div className="form-group col-12">
                                    <label>Header Text/HTML:</label>
                                    <Field
                                        name="headerMarkup"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                </div>

                                <div className="form-group col-4">
                                    <label>Button Text:</label>
                                    <Field
                                        name="buttonText"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                </div>

                                <div className="col-12 mt-3">
                                    <h5>GDPR</h5>
                                </div>
                                <div className="form-group col-12">
                                    <label>Consent Text/HTML:</label>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <Field
                                            className="font-weight-bold"
                                            component={CheckboxBootstrap}
                                            name="consent"
                                            id="consent"
                                        />
                                        <div>Show mandatory consent checkbox</div>
                                    </div>
                                </div>

                                <div className="form-group col-12">
                                    <Field
                                        name="consentMarkup"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        rows="4"
                                        component={TextArea}/>
                                </div>

                                <div className="form-group col-12">
                                    <label>Double Opt In:</label>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <Field
                                            className="font-weight-bold"
                                            component={CheckboxBootstrap}
                                            name="confirmEmail"
                                            id="confirmEmail"
                                        />
                                        <div>Require email confirmation</div>
                                    </div>
                                    <div className="text-small text-muted">The user will receive an extra email and needs to click on a confirmation link.</div>
                                </div>


                                <div className="col-12 mt-3">
                                    <h5>Security</h5>
                                </div>

                                <div className="form-group col-12">
                                    <label>Fraud detection:</label>
                                    <div className="text-small text-muted">Fraud detection is always <span className="font-weight-bold text-success">enabled</span>. It protects your discount codes from trash mailers like '10minutemail.com' and 4785 others.</div>
                                </div>

                                <div className="form-group col-12">
                                    <label>Vaild Domains:</label>
                                    <Field
                                        name="domains"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                    <small className="form-text text-muted">
                                        Add a comma separated list of domains (format: 'https://www.yourdomain.com') that will use the pop-up.<br/>
                                        This is optional but would make your pop-up more secure against fraud.
                                    </small>
                                </div>
                            </div>

                            <div className="form-group mt-4">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-trigger"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>

                )}

            </Formik>
        </div>

    )
};

export default VoucherFormForm