import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import LoadingButton from "../form/LoadingButton";
import * as Yup from "yup";
import configurationApi from "./configurationApi";
import ConfigurationStateBadge from "./ConfigurationStateBadge";
import DateTimeLabel from "./DateTimeLabel";
import {ConfigurationState} from "./configuration";
import CodeListPanel from "../voucher/CodeListPanel";

const ConfigurationInformationPanel = ({accountId, configurationId}) => {

    const [configuration, setConfiguration] = useState({
        name: ""
    });

    useEffect(() => {
        if(configurationId){
            configurationApi
                .load(configurationId)
                .then((response) => {
                    setConfiguration(response.data)
                })
        }
    }, [configurationId]);
    const loading = configuration.id === undefined;

    const handleUpdate = (form) => {
        configurationApi
            .update({
                ...configuration,
                name: form.name
            })
            .then((response) => {
                setConfiguration(response.data)
            })
    };

    const handleCodeListUpdate = (codeList) => {
        configurationApi
            .update({
                ...configuration,
                codeListId: codeList.id
            })
            .then((response) => {
                setConfiguration(response.data)
            })
    };

    const handleToggleState = () => {
        const newState = configuration.state === ConfigurationState.DEACTIVATED ? ConfigurationState.ACTIVE : ConfigurationState.DEACTIVATED;
        configurationApi
            .update({
                ...configuration,
                state: newState
            })
            .then((response) => {
                setConfiguration(response.data)
            })
    };
    const stateButtonLabel = configuration.state === ConfigurationState.DEACTIVATED ? "Activate" : "Deactivate";

    const validationSchemaProfile = Yup.object().shape({
        name: Yup.string()
            .required('This field is required.')
    });

    return (
        <>
            {!loading && (
                <div className="container mt-5">
                    <div className="row">
                        <div className="col">
                            <h1>Configuration</h1>
                            <h4 className="text-dark font-weight-normal">{configuration.name}</h4>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h5>Settings</h5>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <ConfigurationStateBadge state={configuration.state}/><br/>
                            <span className="text-muted text-small">ID: {configuration.id}</span><br/>
                            <span className="text-muted text-small">Changed: <DateTimeLabel date={configuration.lastModified}/></span><br/>
                        </div>
                        <div className="col-6 text-right">
                            <LoadingButton className="btn btn-sm btn-outline-secondary"
                                           indicatorId="update-configuration"
                                           onClick={handleToggleState}
                                           label={stateButtonLabel}/>
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <div className="container mt-5">
                    <h1 className="d-block w-50 bg-loading rounded">&nbsp;</h1>
                    <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                    <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                    <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                </div>
            )}
            <div className="container mt-2">
                <Formik
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validationSchema={validationSchemaProfile}
                    initialValues={{
                        name: configuration.name
                    }}
                    onSubmit={handleUpdate}
                >
                    { props => (
                        <Form>
                            <div>
                                <div className="form-group">
                                    <label>Internal Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                    <small className="form-text text-muted">
                                        This name will be not visible to the end user.
                                    </small>
                                </div>


                                <div className="form-group">
                                    <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                                   indicatorId="update-configuration"
                                                   label="Update"/>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <CodeListPanel
                configurationId={configurationId}
                accountId={accountId}
                codeListId={configuration.codeListId}
                onChange={handleCodeListUpdate}
            />
        </>
    )
};

export default ConfigurationInformationPanel