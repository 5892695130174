import React, {useEffect} from 'react';
import "./BrowserView.scss";

const BrowserView = ({markup, title}) => {

    useEffect(() => {
        if(markup){
            updatePreview(markup)
        }
    }, [markup]);

    const updatePreview = (html) => {
        const iframe = document.getElementById('page-preview');
        iframe.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(html);
    };

    return (
        <div className="browser-view">
            <iframe title="Preview" className="w-100 border bg-white" id="page-preview" src="about:blank"/>
        </div>

    )
};

export default BrowserView