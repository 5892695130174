import React, {useState} from 'react';
import Select from "react-select";

const LanguageSelect = ({
                            field: {name, value, onChange, ...field}, // { name, value, onChange, onBlur }
                            form: {touched, errors, setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            className,
                            label,
                            hideError,
                            ...props
                        }) => {

    const data =  {
        "de": "german",
        "en": "english",
        "es": "spanish",
        "fr": "french",
        "it": "italian"
    };

    const [selected , setSelected] = useState({value: value, label: data[value]});
    const [options] = useState(Object.entries(data).map((item) => {
        return {value: item[0], label: item[1]}
    }));


    const handleOptionChange = (selectedOption) => {
        setSelected(selectedOption);
        setFieldValue(name, selectedOption.value)
    };

    return (
        <Select
            name={name}
            value={selected}
            onChange={(option) => {
                handleOptionChange(option);
            }}
            options={options}
            className='react-select-container w-100'
            classNamePrefix="react-select"
        />
    )
};

export default LanguageSelect