import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import LoadingButton from "../form/LoadingButton";
import * as Yup from "yup";
import accountApi from "./accountApi";
import TextArea from "../form/TextArea";
import Select from "react-select";
import {timezones} from "../common/timezones";

const AccountInformationForm = ({organizationId, accountId}) => {

    const [account, setAccount] = useState({
        name: '',
        senderEmail: '',
        senderAddress: '',
        timezone: ''
    });
    const [selectedZoneIdOption, setSelectedZoneIdOption] = useState({value: "", label: ""});
    const [zoneIdOptions, setZoneIdOptions] = useState([]);

    useEffect(() => {
        let options = [];
        Object.keys(timezones).map((key, index) => {
            options = options.concat({
                value: key,
                label: timezones[key]
            });
            return undefined
        });
        setZoneIdOptions(options)
    }, [accountId]);

    useEffect(() => {
        if (account.timezone) {
            setSelectedZoneIdOption({
                value: account.timezone,
                label: timezones[account.timezone]
            })
        }
    }, [account.timezone]);

    useEffect(() => {
        if (accountId) {
            accountApi
                .load(accountId)
                .then((response) => {
                    setAccount(response.data);
                })
        }
    }, [accountId]);


    const handleZoneIdOptionChange = (selectedOption) => {
        setSelectedZoneIdOption(selectedOption);
    };

    const handleAccountSave = (form) => {
        accountApi
            .update({
                ...account,
                senderAddress: form.senderAddress,
                senderEmail: form.senderEmail,
                timezone: selectedZoneIdOption.value,
                name: form.name
            })
            .then((response) => {
                setAccount(response.data);
            })
    };


    const validationSchemaProfile = Yup.object().shape({
        name: Yup.string()
            .required('This field is required.'),
        senderAddress: Yup.string()
            .required('This field is required.'),
        senderEmail: Yup.string()
            .email('Please enter a valid email.')
            .required('This field is required.'),
        timezone: Yup.string()
            .required('This field is required.')
    });


    return (
        <div>

            <Formik
                enableReinitialize={true}
                validateOnBlur={false}
                validationSchema={validationSchemaProfile}
                initialValues={{
                    senderAddress: account.senderAddress,
                    senderEmail: account.senderEmail,
                    timezone: account.timezone,
                    name: account.name
                }}
                onSubmit={handleAccountSave}
            >
                {props => (
                    <Form>
                        <div>
                            <h5 className="">Account Details</h5>
                            <div className="form-group">
                                <label htmlFor="name">Account Name</label>
                                <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="senderEmail">Sender Email Address</label>
                                <Field
                                    name="senderEmail"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                                <small className="form-text text-muted">The "From:" email address for mails send by this
                                    account.
                                </small>
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="senderAddress">Sender Postal Address</label>
                                </div>
                                <Field
                                    name="senderAddress"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    rows="4"
                                    component={TextArea}/>
                                <small className="form-text text-muted">This text/HTML will be part of the email footer send
                                    by this account.
                                </small>
                            </div>

                            <div className="form-group">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="senderAddress">Timezone</label>
                                </div>
                                <Select
                                    name="timezone"
                                    value={selectedZoneIdOption}
                                    onChange={(option) => {
                                        handleZoneIdOptionChange(option);
                                    }}
                                    options={zoneIdOptions}
                                    className='react-select-container w-100'
                                    classNamePrefix="react-select"
                                />
                                <small className="form-text text-muted">Timezone/Location of this account.
                                </small>
                            </div>

                            <div className="form-group">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-account"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default AccountInformationForm