export class ErrorService {
    cache = [];

    // maybe switch to https://www.npmjs.com/package/pubsub-js

    register(listener) {
        this.cache.push(listener);
    }

    unregister(listenerToRemove) {
        this.cache = this.cache.filter((listener) => {
            return listener !== listenerToRemove
        })
    }

    confirmAll() {
        this.cache.forEach(indicator => {
            indicator.confirm()
        });
    }

    notify(error) {
        this.cache.forEach(indicator => {
            indicator.notify(error)
        });
    }

}

const errorService = new ErrorService();
export {errorService}