import React from 'react';
import {CodeListTypeNames} from "../configuration/configuration";

const CodeListLabel = ({type}) => {

    return (
        <div className="d-flex align-items-center">
            <div className="mr-2 border rounded">
                <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/codelist_"+type+".png"}/>
            </div>
            <div>{CodeListTypeNames[type]}</div>
        </div>
    )
};

export default CodeListLabel