import React, {useEffect, useState} from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import Footer from "../footer/Footer";
import auth from "../auth/auth";
import configurationApi from "./configurationApi";
import NewConfigurationPanel from "./NewConfigurationPanel";
import ConfigurationListItem from "./ConfigurationListItem";
import Page from "../common/Page";

const ConfigurationOverviewPage = () => {

    const accountId = auth.data().accountId;
    const [configurations, setConfigurations] = useState();
    const loading = configurations === undefined;

    const handleDelete = (configuration) => {
        configurationApi
            .delete(configuration)
            .then((response) => {
                configurationApi
                    .find(accountId, "name,asc")
                    .then((response) => {
                        setConfigurations(response.data)
                    })
            })
    };

    useEffect(() => {
        configurationApi
            .find(accountId, "name,asc")
            .then((response) => {
                setConfigurations(response.data)
            })
    }, [accountId]);

    return <Page>
        <PrivateNavigation/>
        <section className="section">

            <div className="container mt-5 d-flex ">
                <h1>Configurations</h1>
            </div>
            <div className="container text-right">
                <NewConfigurationPanel className="btn-sm"/>
            </div>

            {!loading && (
                <div className="container mt-2">
                    <div className="mt-4">
                        {configurations.map((item, key) =>
                            <ConfigurationListItem
                                configuration={item}
                                key={item.id}
                                onRemove={handleDelete}
                            />
                        )}
                    </div>
                </div>
            )}

            {loading && (
                <div className="container mt-5 text-center">
                    <p className="bg-loading rounded w-100 d-inline-block">
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                    </p>
                </div>
            )}
        </section>
        <Footer/>

    </Page>
};

export default ConfigurationOverviewPage