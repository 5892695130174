import React, { useState } from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import {Link} from "react-router-dom";
import LoadingButton from "../form/LoadingButton";
import TextInput from "../form/TextInput";
import authApi from "./authApi";
import PublicNavigation from "../navigation/PublicNavigation";
import Footer from "../footer/Footer";
import Page from "../common/Page";

const LoginPage = () => {

    // rendered on server side request
    // start
    const sessionId = "1e22ad6a-f56c-4d2d-b146-34cb9aff4101";
    // end


    const [authError, setAuthError] = useState(false);
    const setCookie = (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        const expires = "expires="+ d.toUTCString();

        if (window.location.hostname.indexOf("localhost") >= 0) {
            document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=localhost;path=/";
        } else {
            document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.voucherjet.com;path=/;SameSite=None;secure";
        }

    };

    const handleLogin = (values) => {
        setAuthError(false);
        authApi
            .login(values.email, values.password)
            .then(() => {
                setCookie("sid", sessionId,356);
                window.location.href = "/";
            })
            .catch((error) => {
                console.log(error)
                setAuthError(true)
            })
    };


    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.'),
        email: Yup.string()
            .email('Please enter a valid email.')
            .required('This field is required.'),
    });

    return <Page>
        <PublicNavigation/>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="container container--mini mt-5">
                        <h1 className="mb-5 text-center">Log In</h1>

                        {authError && (
                            <div className="alert alert-danger text-small" role="alert">
                                Please check username and password.
                            </div>
                        )}

                        <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validationSchema={validationSchema}
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            onSubmit={handleLogin}
                        >
                            { props => (
                                <Form>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    component={TextInput}/>
                                            </div>
                                            <div className="form-group">
                                                <div className="d-flex justify-content-between">
                                                    <label htmlFor="password">Password</label>
                                                    <Link className="text-small" to="/forgot">Forgot password?</Link>
                                                </div>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder=""
                                                    component={TextInput}/>
                                            </div>
                                            <div className="form-group">
                                                <LoadingButton className="btn btn-outline-success btn-block mb-4 mt-5"
                                                               indicatorId="login"
                                                               label="Log In"/>
                                            </div>
                                        </div>
                                </Form>
                            )}
                        </Formik>


                        <p className="text-small text-center text-gray-soft">
                            Don't have an account yet? <a href="https://www.voucherjet.com/en/signup">Sign up</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default LoginPage