import React from 'react';

const ManyChatMessageEditor = ({response, onChange}) => {

    const handleBodyChange = (index, e) => {
        const messages = response.content.messages;
        messages[index] = {
            "type" : "text",
            "text" : e.target.value
        };
        onChange({
            ...response,
            content: {
                ...response.content,
                messages : messages
            }
        })
    };

    const addText = () => {
        const messages = response.content.messages;
        messages.push({
            "type" : "text",
            "text" : "Hello!"
        });
        onChange({
            ...response,
            content: {
                ...response.content,
                messages : messages
            }
        })
    };

    const remove = (index) => {
        response.content.messages.splice(index,1);
        onChange({
            ...response,
            content: {
                ...response.content,
                messages : response.content.messages
            }
        })
    };

    return (
        <div>
            <div>
            {response.content.messages.map((item, index) =>
                <div className="d-flex justify-content-between" key={index}>
                    <div className="mb-2 w-100">
                            <textarea
                                value={item.text}
                                className="form-control text-small bg-loading rounded-4 text-dark"
                                placeholder=""
                                rows="4"
                                onChange={(e) => {handleBodyChange(index, e)}}
                            />
                    </div>
                    <div>
                        <span onClick={(e) => {remove(index)}} className="cursor-pointer text-muted text-small position-absolute pl-1">
                            <i className="fa fa-minus-circle mr-1"/>
                        </span>
                    </div>

                </div>
            )}
            </div>
            <div className="text-muted text-uppercase text-small">
            Add Element:
            </div>
            <div>
                <button onClick={addText} type="button" className="btn btn-sm badge btn-outline-success cursor-pointer">
                    <i className="fa fa-plus-circle mr-1"/>Text
                </button>
            </div>
        </div>
    )
};

export default ManyChatMessageEditor