import React from 'react';
import auth from "../auth/auth";
import paymentApi from "./paymentApi";
import LoadingButton from "../form/LoadingButton";

const PaymentCustomerPortalButton = () => {

    const organizationId = auth.data().organizationId;


    const createPaymentSession = () => {
        paymentApi
            .createCustomerPortalSession(organizationId)
            .then((response) => {
                const redirectUrl = response.data.redirectUrl
                // redirect to Stripe
                window.location.href = redirectUrl
            })
    };

    return <div>


        <LoadingButton className="btn btn-sm btn-outline-success"
                       confirmIconClassName="fa-spin fa-spinner"
                       indicatorId="create-customer-portal-session"
                       label="Open Payment Settings"
                       onClick={createPaymentSession}
        />
        <div className="text-small text-muted mt-1">You will be redirected to our payment partner Stripe to manage your
            settings.
        </div>


    </div>
};

export default PaymentCustomerPortalButton