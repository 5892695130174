import React from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import Footer from "../footer/Footer";
import auth from "../auth/auth";
import Page from "../common/Page";
import paymentApi from "./paymentApi";
import LoadingButton from "../form/LoadingButton";

const PaymentUpgradePage = () => {

    const organizationId = auth.data().organizationId;


    const createPaymentSession = () => {
        paymentApi
            .createCheckoutSession(organizationId)
            .then((response) => {
                const redirectUrl = response.data.redirectUrl
                // redirect to Stripe
                window.location.href = redirectUrl
            })
    };

    return <Page>
        <PrivateNavigation/>
        <section className="section">
            <div className="container mt-5">
                <h1>Upgrade To Paid Plan</h1>


                <iframe title="pricing" height="510px" className="border-0 w-100 mt-5" src="https://www.voucherjet.com/en/pricing-slider"/>

                <div className="container text-center h-50">
                    <LoadingButton className="btn btn-outline-success"
                                   confirmIconClassName="fa-spin fa-spinner"
                                   indicatorId="create-checkout-session"
                                   label="Create Subscription"
                                   onClick={createPaymentSession}
                    />
                    <div className="text-small text-muted mt-1">You will be redirected to our payment partner Stripe.</div>
                </div>
            </div>



          
        </section>
        <Footer/>
    </Page>
};

export default PaymentUpgradePage