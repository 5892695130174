import React from 'react';

const ManyChatAppResponseConfigInformation = ({config, type, accountId}) => {

    return (
        <div>
            <div className="text-small text-muted">
                Depending on the case these values will be available in your ManyChat flow
                right after the 'Code Distribution App' action step.
            </div>
            <div className="row mt-2 text-small">
                <div className="col-4">
                    <div className=" text-muted mb-2">Response with code:</div>
                    <div><div className="w-25 d-inline-block">State:</div><code>OK</code></div>
                    <div><div className="w-25 d-inline-block">Code:</div><code>CODE-1234</code></div>
                </div>
                <div className="col-4">
                    <div className=" text-muted mb-2">Response on empty code list/limit:</div>
                    <div><div className="w-25 d-inline-block">State:</div><code>LIMITED</code></div>
                    <div><div className="w-25 d-inline-block">Code:</div><code>-</code></div>
                </div>
                <div className="col-4">
                    <div className="  text-muted mb-2">Response on deactivated configuration:</div>
                    <div><div className="w-25 d-inline-block">State:</div><code>DEACTIVATED</code></div>
                    <div><div className="w-25 d-inline-block">Code:</div><code>-</code></div>
                </div>
            </div>
        </div>
    )
};

export default ManyChatAppResponseConfigInformation