import React, {useState} from 'react';
import CopyButton from "../../common/CopyButton";
import {Link} from "react-router-dom";
import ApiKeyLabel from "../../organization/ApiKeyLabel";

const ManyChatAppEndpointConfigInformation = ({trigger, accountId}) => {

    const [helpVisible, setHelpVisible] = useState(false);

    const handleToggleHelpVisible = () => {
        setHelpVisible(!helpVisible)
    };

    return (
        <div>
            <div className="text-small text-muted">
                This id any key are necessary to connect the ManyChat 'Code Distribution App' and VoucherJet.
            </div>
            <div className="text-small w-50 mt-2">
                <div className="w-25 d-inline-block">Trigger Id:&nbsp;</div>
                <code className="w-50 d-inline-block">{trigger.id}</code>
                <CopyButton className="ml-4" text={trigger.id} label="Copy"/>
            </div>
            <div className="text-small w-50">
                <div className="w-25 d-inline-block">API Key:&nbsp;</div>
                <ApiKeyLabel accountId={accountId}/>
            </div>
            <div className="mt-2">
                <div className="text-small">
                    <i className="far fa-question-circle mr-1 text-success"></i><Link to="#" onClick={handleToggleHelpVisible}>How to connect your 'ManyChat App' with VoucherJet?</Link>
                </div>
            </div>

            <div className={helpVisible ? '' : 'd-none'}>
                <div className="text-small mt-4 ">
                    After you have added the ManyChat App to your flow action you need to add two configuration values into
                    the corresponding fields on the ManyChat side.
                </div>

                <div className="text-small mt-2 ">
                    <code>Trigger Id</code> - this will connect the ManyChat App with this Configuration and Code List.
                </div>
                <div className="text-small mt-1 ">
                    <code>API Key</code> - this will secure the connection and identify your account.
                </div>

                <div className="mt-4 text-small"><i className="far fa-question-circle mr-1 text-success"></i>
                    <a href="https://www.voucherjet.com/en/docs/how-to-add-unique-coupon-codes-to-manychat-custom-user-fields" rel="noreferrer noopener" target="_blank">Read the full step-by-step guide</a>
                </div>
            </div>
        </div>
    )
};

export default ManyChatAppEndpointConfigInformation