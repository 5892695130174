export class IndicatorService {
    cache = [];

    register(indicator) {
        this.cache.push(indicator);
    }

    unregister(spinnerToRemove) {
        this.cache = this.cache.filter((indicator) => {
            return indicator !== spinnerToRemove
        })
    }

    show(indicatorId) {
        this.cache.forEach(indicator => {
            if (indicator.indicatorId === indicatorId) {
                indicator.show()
            }
        });
    }

    hide(indicatorId) {
        this.cache.forEach(indicator => {
            if (indicator.indicatorId === indicatorId) {
                indicator.hide()
            }
        });
    }

    hideAll() {
        this.cache.forEach(indicator => {
            indicator.hide()
        });
    }

}

const indicatorService = new IndicatorService();
export {indicatorService}