import React from 'react';
import axios from 'axios/index'
import auth from "./auth/auth";
import {BrowserRouter as Router, Route} from "react-router-dom";
import './bootstrap-theme.scss';
import LoginPage from "./auth/LoginPage";
import RegistrationPage from "./auth/RegistrationPage";
import DashboardPage from "./dashboard/DashboardPage";
import PrivateRoute from "./auth/PrivateRoute";
import SettingsEditPage from "./organization/SettingsEditPage";
import {indicatorService} from "./loadingindicator/IndicatorService";
import {errorService} from "./error/ErrorService";
import GlobalError from "./error/GlobalError";
import RequestPasswordPage from "./auth/RequestPasswordPage";
import ResetPasswordPage from "./auth/ResetPasswordPage";
import ConfigurationEditPage from "./configuration/ConfigurationEditPage";
import ConfigurationOverviewPage from "./configuration/ConfigurationOverviewPage";
import PostRegistrationPage from "./auth/PostRegistrationPage";
import RegistrationFormPage from "./auth/RegistrationFormPage";
import PaymentUpgradePage from "./payment/PaymentUpgradePage";
import PaymentSuccessPage from "./payment/PaymentSuccessPage";


window.toggles = {
    FT_USAGE_LIMIT: true,

    FT_LANDINGPAGE_AMZLEAD_FORM: true,
    FT_LANDINGPAGE_AMZLEAD_LINK: true,
    FT_VOUCHER_LINK: true,
    FT_MANYCHAT_APP: true,

    FT_ADD_TRIGGER: false,
    FT_ADD_ACTION: false
};

// Add a request interceptor on load
axios.interceptors.request.use(
    config => {
        errorService.confirmAll();
        if (config.indicatorId) {
            indicatorService.show(config.indicatorId);
        }
        if (config.headers.authorization === undefined) {
            config.headers.authorization = "Bearer " + auth.data().token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

axios.interceptors.response.use(
    response => {
        if (response.config.indicatorId) {
            indicatorService.hide(response.config.indicatorId);
        }
        //indicatorService.hideAll();
        return response
    },
    error => {
        indicatorService.hideAll();

        console.error("error.response: ", JSON.stringify(error.response));
        if (error.response) {
            console.error("status: ", error.response.status);
        }

        if (error.response && 403 === error.response.status) {
            errorService.notify({
                message: "Your session has expired or you might not be allow to perform this action.<br/>",
                title: "Please Log In Again",
                modal: true
            });
        }

        if (error.response && (
            500 === error.response.status ||
            404 === error.response.status
        )) {
            errorService.notify({
                message: "Server error: '" + error.response.data.message + "'"
            });
        }

        if (error.response === undefined) {
            errorService.notify({
                message: "Application Error: '" + error.toString() + "'"
            });
        }

        return Promise.reject(error);
    }
);


function App() {
    return (
        <Router>
            <div id="app">
                <PrivateRoute exact path="/" component={DashboardPage}/>
                <PrivateRoute exact path="/organization/settings" component={SettingsEditPage}/>
                <PrivateRoute exact path="/configurations/:id" component={ConfigurationEditPage}/>
                <PrivateRoute exact path="/configurations" component={ConfigurationOverviewPage}/>
                <PrivateRoute exact path="/payment/upgrade" component={PaymentUpgradePage}/>
                <PrivateRoute exact path="/payment/success" component={PaymentSuccessPage}/>
                <Route exact path="/forgot" component={RequestPasswordPage}/>
                <Route exact path="/forgot/reset" render={(props) => <ResetPasswordPage {...props} />}/>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/signup" component={RegistrationPage}/>
                <Route exact path="/signup/form" component={RegistrationFormPage}/>
                <Route exact path="/signup/thankyou" component={PostRegistrationPage}/>
                <GlobalError/>
            </div>
        </Router>

    );
}

export default App;
