import React, {useState} from 'react';
import Modal from "../modal/Modal";
import LoadingButton from "../form/LoadingButton";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {ActionType} from "../configuration/configuration";
import actionApi from "./actionApi";
import LanguageSelect from "../configuration/LanguageSelect";

const NewActionPanel = ({configurationId, className, onCreate = () => {},}) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [template, setTemplate] = useState(ActionType.MANYCHAT_RESPONSE);

    const handleToggleModalVisible = () => {
        setModalVisible(!modalVisible)
    };

    const handleChangeTemplate = (value) => {
        setTemplate(value)
    };

    const handleCreateConfig = (form) => {

        const action = {
            configurationId: configurationId,
            type: template,
            language: form.language,
            config: {} // default will be set by server depending on type
        };
        actionApi
            .create(action)
            .then((response) => {
                handleToggleModalVisible();
                onCreate(response.data)
            });
    };

    const validationSchemaProfile = Yup.object().shape({
    });

    return (
        <div>
            <button className={"btn btn-sm btn-outline-success " + className} onClick={handleToggleModalVisible}>
                Add Action
            </button>


            <Modal isOpen={modalVisible} onClose={handleToggleModalVisible} additionalClassNamePrefix="new-configuration">
                <Formik
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validationSchema={validationSchemaProfile}
                    initialValues={{
                        language: "en"
                    }}
                    onSubmit={handleCreateConfig}
                >
                    { props => (
                        <Form>

                            <div className="modal-header">
                                <h5 className="modal-title">Add Action</h5>
                                <button type="button" className="close" onClick={handleToggleModalVisible}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group">
                                    <label>
                                        What action should be triggered?
                                    </label>
                                </div>

                                <div className="">

                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate(ActionType.MANYCHAT_RESPONSE)}>
                                        <div className={"p-3 border rounded " + (template === ActionType.MANYCHAT_RESPONSE ? "border-success" : "")}>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+ActionType.MANYCHAT_RESPONSE+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">ManyChat</div>
                                            </div>
                                            <div className="text-small">Reply with a code via chat.</div>
                                        </div>
                                    </div>
                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate(ActionType.CHATFUEL_RESPONSE)}>
                                        <div className={"p-3 border rounded " + (template === ActionType.CHATFUEL_RESPONSE ? "border-success" : "")}>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+ActionType.CHATFUEL_RESPONSE+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">Chatfuel</div>
                                            </div>
                                            <div className="text-small">Reply with a code via chat.</div>
                                        </div>
                                    </div>
                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate(ActionType.CODE_MAIL)}>
                                        <div className={"p-3 border rounded " + (template === ActionType.CODE_MAIL ? "border-success" : "")}>
                                            <div className="font-weight-bold"></div>
                                            <div className="font-weight-bold"></div>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+ActionType.CODE_MAIL+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">Code Mail</div>
                                            </div>
                                            <div className="text-small">Send a email including a code.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3 ">
                                    <label htmlFor="firstName">Language</label>
                                    <Field
                                        name="language"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={LanguageSelect}/>
                                    <small className="form-text text-muted">
                                        Language used to create mail, page and chat bot message templates. <br/>Basically the
                                        language of your customers.
                                    </small>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <LoadingButton className="btn btn-outline-success btn-sm"
                                               indicatorId="create-trigger"
                                               label="Add"/>
                                <button type="button"
                                        className="btn btn-light"
                                        onClick={handleToggleModalVisible}>Cancel</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
};

export default NewActionPanel