import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {default as ReactModal} from 'react-modal'
import './Modal.css'

export default class Modal extends Component {
    componentDidMount() {
        const app = document.getElementById('app')
        if (app) {
            ReactModal.setAppElement('#app')
        }
    }

    static defaultProps = {
        isOpen: false,
        additionalClassName: ''
    };

    static propTypes = {
        onClose: PropTypes.func,
        isOpen: PropTypes.bool,
        additionalClassNamePrefix: PropTypes.string
    };

    render() {
        const {
            isOpen, onClose,
            children,
            additionalClassNamePrefix
        } = this.props;
        return (
            <div className={`overlay-wrapper ${additionalClassNamePrefix}-wrapper`}>
                <ReactModal

                    isOpen={isOpen}
                    className={`overlay-content ${additionalClassNamePrefix}-content`}
                    overlayClassName={`overlay-curtain ${additionalClassNamePrefix}-curtain`}
                    onRequestClose={onClose}
                >
                    <div className="modal d-block">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                {children}
                            </div>
                        </div>
                    </div>

                </ReactModal>
            </div>
        )
    }
}
