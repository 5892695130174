import React, {useState} from 'react';
import {ActionTypeNames} from "../configuration/configuration";
import ChatBotResponseConfigInformation from "./ChatBotResponseConfigInformation";
import Modal from "../modal/Modal";
import ChatBotResponseConfigEditPanel from "./ChatBotResponseConfigEditPanel";
import actionApi from "./actionApi";
import CodeMailConfigEditPanel from "./CodeMailConfigEditPanel";
import CodeMailConfigInformation from "./CodeMailConfigInformation";
import ModalConfirmation from "../modal/ModalConfirmation";
import CodePageConfigInformation from "./codepage/CodePageConfigInformation";
import CodePageConfigEditPanel from "./codepage/CodePageConfigEditPanel";
import ManyChatAppResponseConfigInformation from "./manychatapp/ManyChatAppResponseConfigInformation";

const ActionListItem = ({action, accountId, onUpdate = () => {}, onRemove = () => {}}) => {

    const [modalVisible, setModalVisible] = useState(false);

    const [editModalVisible, setEditModalVisible] = useState(false);

    const handleToggleEditModalVisible = () => {
        setEditModalVisible(!editModalVisible)
    };

    const handleEdit = () => {
        setEditModalVisible(true);
    };

    const handleToggleModalVisible = () => {
        setModalVisible(!modalVisible)
    };

    const handleDelete = (action) => {
        actionApi
            .delete(action)
            .then((response) => {
                handleToggleModalVisible();
                onRemove(action)
            })
    };

    const handleUpdate = (action) => {
        actionApi
            .update(action)
            .then((response) => {
                const updatedAction = response.data;
                onUpdate(updatedAction)
            })
    };

    const loading = action.id === undefined;
    const {
        id,
        type,
        config
    } = action;

    return (
        <>
            <ModalConfirmation
                isOpen={modalVisible}
                onSubmit={() => handleDelete(action)}
                onClose={handleToggleModalVisible}
                buttonLabel="Delete"
                buttonClassName="btn-danger"
                indicatorId="delete-action"
                title="Delete Action">
                <p>Delete '{ActionTypeNames[type]}' action?</p>
                <div className="alert alert-warning text-small" role="alert">
                    Make sure the related configuration is deactivated.
                </div>
            </ModalConfirmation>

            <Modal
                isOpen={editModalVisible}
                onClose={handleToggleEditModalVisible}
                additionalClassNamePrefix="modal-lg-vj edit-code-list"

            >
                <div className="modal-header">
                    <h5 className="modal-title">Edit Action</h5>
                    <button type="button" className="close" onClick={handleToggleEditModalVisible}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {{
                        CHATFUEL_RESPONSE: <ChatBotResponseConfigEditPanel actionId={id} action={action} onUpdate={handleUpdate}/>,
                        MANYCHAT_RESPONSE: <ChatBotResponseConfigEditPanel actionId={id} action={action} onUpdate={handleUpdate}/>,
                        CODE_MAIL: <CodeMailConfigEditPanel actionId={id} action={action} onUpdate={handleUpdate}/>,
                        CODE_PAGE: <CodePageConfigEditPanel actionId={id} action={action} onUpdate={handleUpdate}/>
                    }[type]}
                </div>
                <div className="modal-footer">
                    <button type="button"
                            className="btn btn-light"
                            onClick={handleToggleEditModalVisible}>Close</button>
                </div>
            </Modal>

            {!loading && (
                <div className="border rounded p-4 mt-4">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="mr-2 border rounded">
                                <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+type+".png"}/>
                            </div>
                            <div>{ActionTypeNames[type]}</div>
                        </div>
                        <div>
                            {window.toggles.FT_ADD_ACTION && (
                                <button
                                    className="ml-1 btn btn-sm btn-outline-secondary"
                                    onClick={() => {handleToggleModalVisible(id)}}>Remove</button>
                            )}
                            {{
                                CHATFUEL_RESPONSE: <button className="ml-1 btn btn-sm btn-outline-secondary" onClick={handleEdit}>Edit</button>,
                                MANYCHAT_RESPONSE: <button className="ml-1 btn btn-sm btn-outline-secondary" onClick={handleEdit}>Edit</button>,
                                MANYCHAT_APP_RESPONSE: <span/>,
                                CODE_MAIL: <button className="ml-1 btn btn-sm btn-outline-secondary" onClick={handleEdit}>Edit</button>,
                                CODE_PAGE: <button className="ml-1 btn btn-sm btn-outline-secondary" onClick={handleEdit}>Edit</button>
                            }[type]}



                        </div>
                    </div>
                    <div className="mt-2">
                        {{
                            CHATFUEL_RESPONSE: <ChatBotResponseConfigInformation config={config} type={type}/>,
                            MANYCHAT_RESPONSE: <ChatBotResponseConfigInformation config={config} type={type}/>,
                            MANYCHAT_APP_RESPONSE: <ManyChatAppResponseConfigInformation config={config} accountId={accountId} type={type}/>,
                            CODE_MAIL: <CodeMailConfigInformation config={config}/>,
                            CODE_PAGE: <CodePageConfigInformation config={config}/>
                        }[type]}
                    </div>
                </div>
            )}
            {loading && (
                <div className="mt-4">
                    <p className="bg-loading rounded w-100 d-inline-block">
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                    </p>
                </div>
            )}


        </>
    )
};

export default ActionListItem