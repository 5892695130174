import React from 'react';
import axios from "axios";
import "./HtmlEditor.scss";


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditor from "react-froala-wysiwyg";


const HtmlEditor = ({html, onChange}) => {

    console.log("HtmlEditor init")

    const cleanUpAndUpdate = (newHtml) => {
        const lastTagIndex = newHtml.indexOf("</html>");
        if (lastTagIndex > 0) {
            console.log("HtmlEditor cleanUpAndUpdate with tag")
            const cleanHtml = newHtml.substring(0, lastTagIndex + 7);
            onChange(cleanHtml);
        } else {
            console.log("HtmlEditor cleanUpAndUpdate")
            onChange(newHtml);
        }
    };


    // NEXT: try 4.x plain again

    // https://codesandbox.io/s/froala-react-forked-i9p7u?file=/src/FroalaEditor.tsx
    // see: https://froala.com/wysiwyg-editor/docs/options/
    // update to 4.x -> https://github.com/froala/wysiwyg-editor/issues/4262
    const editorConfig = {
        toolbarSticky: false,
        key: "jUA1yC1D5E2D1B1F1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2F1B10B2C1E6C1A1",
        pluginsEnabled: ['aviary', 'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'wordPaste'],
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove'],
        toolbarButtons: {
            moreText: {
                buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineClass", "inlineStyle", "clearFormatting"]
            },
            moreParagraph: {
                buttons: ["alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "lineHeight", "outdent", "indent", "quote"]
            },
            moreRich: {
                buttons: ["insertLink", "insertImage", "insertTable", "fontAwesome", "specialCharacters", "embedly", "insertHR"]
            },
            moreMisc: {
                buttons: ["undo", "redo", "fullscreen", "print", "getPDF", "spellChecker", "selectAll", "html", "help"],
                align: "right",
                buttonsVisible: 2
            }
        },
        zIndex: 2501,
        height: 800,
        iframe: true,
        fullPage: true,
        saveInterval: 0,
        charCounterCount: false,
        htmlExecuteScripts: false,
        htmlRemoveTags: ['test'],
        htmlUntouched: true,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        events: {
            'initialized': function () {
                this.html.set(html);
            },
            'blur': function () {
                // TODO [froala] remove once the license is purchased
                const newHtml = this.html.get();
                cleanUpAndUpdate(newHtml);
            },
            'codeView.update': function () {
                // TODO [froala] remove once the license is purchased
                const newHtml = this.html.get();
                cleanUpAndUpdate(newHtml);
            },
            'image.beforeUpload': function (images) {
                const apiUrl = 'https://api.imgur.com/3/image';
                const apiKey = '2b01b62af1ac7b6';

                const editor = this;
                const data = new FormData();
                data.append('image', images[0]);

                axios({
                    method: 'post',
                    url: apiUrl,
                    data: data,
                    headers: {
                        'accept': 'application/json',
                        'authorization': 'Client-ID ' + apiKey,
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    }
                }).then(res => {
                    editor.image.insert(res.data.data.link, null, null, editor.image.get());
                }).catch(err => {
                    console.log(err);
                });
                return false;
            }
        }
    }

    // seems to be the popup, test on plain page
    return (
        <div id="froala-editor-wrapper" className="fr-editor">
            <div id="froala-editor">
                <FroalaEditor config={editorConfig}/>
            </div>
        </div>
    )
};

export default HtmlEditor