import React, {useEffect} from 'react';

const CodeMailConfigInformation = ({config}) => {

    const {subject, body} = config;

    useEffect(() => {
        if(body){
            updatePreview(body)
        }
    }, [body]);

    const updatePreview = (html) => {
        const iframe = document.getElementById('mail-preview');
        iframe.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(html);
    };

    return (
        <div className="mx-auto">
            <div>
                <div className="mt-3 font-weight-bold">
                    <span dangerouslySetInnerHTML={{__html: subject}}></span>
                </div>
            </div>
            <div className="mt-3">
                <div className="html-editor p-1" style={{"height": "400px", "background": "url('/assets/images/airmail.png') repeat"}}>
                    <iframe title="Preview" className="w-100 border bg-white h-100" id="mail-preview" src="about:blank"/>
                </div>
            </div>
        </div>
    )
};

export default CodeMailConfigInformation