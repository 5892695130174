import React, {useState} from 'react';
import {Link} from "react-router-dom";
import authApi from "../auth/authApi";

const PrivateNavigation = () => {

    const [collapseMode, setCollapseMode] = useState(true);

    const handleToggleCollapseMode = () => {
        setCollapseMode(!collapseMode)
    };

    const handleLogout = () => {
        authApi
            .logout()
            .then(() => {
                window.location.href = "/login";
            })
            .catch((error) => {
                console.error(error)
            })
    };

    return <div>

        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white">
            <div className="container  py-3 px-4">
                <a href="https://www.voucherjet.com" className="navbar-brand">
                    <img style={{"height" : "24px"}} src="/assets/images/voucherjet-logo.png" alt="voucherjet logo"/>
                </a>
                <div className="d-flex ml-auto">
                    <button onClick={handleToggleCollapseMode} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#globalNavbar"
                            aria-controls="globalNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                </div>
                <div className={collapseMode ? "collapse navbar-collapse" : "navbar-collapse"}>
                    <ul className="navbar-nav ml-lg-4 mr-auto order-1">
                        <li className="nav-item ml-2 ml-lg-4">
                            <Link className="nav-link" to="/" >
                                Dashboard
                            </Link>
                        </li>
                        <li className="nav-item ml-2 ml-lg-4">
                            <Link className="nav-link" to="/organization/settings" >
                                Settings
                            </Link>
                        </li>
                        <li className="nav-item ml-2 ml-lg-4">
                            <Link className="nav-link" to="/configurations" >
                                Configurations
                            </Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-lg-flex order-3">
                        <li className="nav-item ml-2">
                            <Link className="nav-link" to={''} onClick={handleLogout} >
                                Log out
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <div className="mt-3">
            &nbsp;<br/>
            &nbsp;<br/>
        </div>

    </div>;
};

export default PrivateNavigation;