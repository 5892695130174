import React, {useEffect, useState} from 'react';
import organizationApi from "./organizationApi";
import DateLabel from "../configuration/DateLabel";
import UsageExceededPanel from "./UsageExceededPanel";
import UsageUpgradePanel from "./UsageUpgradePanel";

const UsageQuotaPanel = ({organizationId, hideInfoLine = false}) => {

    const [uploadQuota, setUploadQuota] = useState(undefined);
    const [deliveryQuota, setDeliveryQuota] = useState(undefined);
    const loading = uploadQuota === undefined || deliveryQuota === undefined;

    useEffect(() => {
        organizationApi
            .usage(organizationId)
            .then((response) => {
                setDeliveryQuota(response.data.delivery)
                setUploadQuota(response.data.upload)
            })
    }, [organizationId]);


    return (
        <div>

            {!loading && (

                <div>
                    {!hideInfoLine && (
                        <div>
                            During the usage interval <b><DateLabel date={uploadQuota.started}/>&nbsp;-&nbsp;<DateLabel
                            date={uploadQuota.ended}/></b> you have uploaded <b>{uploadQuota.count}</b> and distributed <b>{deliveryQuota.count}</b> codes.
                        </div>
                    )}

                    {uploadQuota.status === 'OK' && (
                        <div>
                            <div>
                                You have not reached the upload limit of <b>{uploadQuota.limit}</b> codes.<br/>
                            </div>
                            <UsageUpgradePanel limit={uploadQuota.limit}/>
                        </div>
                    )}
                    {uploadQuota.status === 'EXCEEDED' && (
                        <div>
                           <UsageExceededPanel limit={uploadQuota.limit}/>
                        </div>
                    )}


                </div>
            )}

            {loading && (
                <p className="bg-loading rounded w-50 d-inline-block">
                    &nbsp;<br/>
                </p>
            )}

        </div>
    )
};

export default UsageQuotaPanel