import React from 'react';
import {TriggerTypeNames} from "./configuration";

const TriggerLabel = ({type}) => {

    const label = TriggerTypeNames[type];

    return (
        <div className="d-flex align-items-center h-100">
            <div className="mr-1 border rounded">
                <img alt="icon" style={{"margin": "2px"}} width="20" src={"/assets/images/integration_"+type+".png"}/>
            </div>
            <div className="">
                <b>'{label}'</b>
            </div>
        </div>
    )
};

export default TriggerLabel