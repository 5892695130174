import React, { useState } from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import queryString from 'query-string'
import LoadingButton from "../form/LoadingButton";
import TextInput from "../form/TextInput";
import authApi from "./authApi";
import PublicNavigation from "../navigation/PublicNavigation";
import Footer from "../footer/Footer";
import {Link} from "react-router-dom";
import Page from "../common/Page";

const ResetPasswordPage = (props) => {

    const [authError, setAuthError] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);


    const handleReset = (values) => {
        const params = queryString.parse(props.location.search);
        setAuthError(false);
        setAuthSuccess(false);
        authApi
            .passwordReset(params.token, values.newPassword)
            .then(() => {
                setAuthSuccess(true)
            })
            .catch(() => {
                setAuthError(true)
            })
    };


    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.'),
        confirmedPassword: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.')
            .test('password-match' , 'Confirmation password does not match', function (value) {
                return this.parent.newPassword === value;
            })
    });

    return <Page>
        <PublicNavigation/>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="container container--mini mt-5">
                        <h1 className="mb-5 text-center">Set New Password</h1>

                        {authError && (
                            <div className="alert alert-danger text-small" role="alert">
                                The reset session is expired. <br/>
                                Please <Link to="/forgot">request a new password</Link> again.
                            </div>
                        )}


                        {authSuccess && (
                            <div className="alert alert-success text-small" role="alert">
                                The password has been changed.<br/>
                                <Link to="/login">Please log in</Link> with your new password.
                            </div>
                        )}

                        <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validationSchema={validationSchema}
                            initialValues={{
                                newPassword: "",
                                confirmedPassword : ""
                            }}
                            onSubmit={handleReset}
                        >
                            { props => (
                                <Form>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="newPassword">New password</label>
                                                <Field
                                                    name="newPassword"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder=""
                                                    component={TextInput}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="confirmedPassword">Confirm new password</label>
                                                <Field
                                                    name="confirmedPassword"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder=""
                                                    component={TextInput}/>
                                            </div>
                                            <div className="form-group">
                                                <LoadingButton className="btn btn-outline-success btn-block mb-4 mt-5"
                                                               indicatorId="password-reset"
                                                               label="Set Password"/>
                                            </div>
                                        </div>
                                </Form>
                            )}
                        </Formik>



                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default ResetPasswordPage