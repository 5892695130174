import React, {useEffect, useState} from 'react';
import {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
const DataTable = ({page, totalSize, content, columns, defaultSorted, onTableChange, onSelectChange, selectedIds }) => {

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(selectedIds);
    }, [selectedIds]);

    const handleOnSelect = (row, isSelect) => {
        let ids;
        if (isSelect) {
            ids = [...selected, row.id]
        } else {
            ids = selected.filter(x => x !== row.id)
        }
        setSelected(ids);
        onSelectChange(ids);
    };

    const handleOnSelectAll = (isSelect, rows) => {
        let ids;
        if (isSelect) {
            ids = rows.map(r => r.id);
        } else {
            ids = []
        }
        setSelected(ids);
        onSelectChange(ids);
    };

    const options = {
        custom: true,
        page: page,
        sizePerPage: 20,
        totalSize: totalSize,
        showTotal: true,
        paginationSize: 4,
        hideSizePerPage: true,
        withFirstAndLast: false,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total text-muted small">
                Showing {from} to {to} of {size} Codes
            </span>
        )
    };

    const sortingColumns = columns.map((col) => {
        if (col.sort) {
            return {
                ...col,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-1 fas fa-sort text-muted"></i>);
                    else if (order === 'asc') return (<i className="ml-1 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-1 fas fa-sort-down"></i>);
                    return null;
                }
            }
        } else {
            return col
        }
    });

    // TODO: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Selection%20Management&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };


    return (
        <div>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <div>
                            <div className="small">
                                <BootstrapTable
                                    keyField="id"

                                    bootstrap4
                                    bordered={false}
                                    selectRow={ selectRow }
                                    remote
                                    data={content}
                                    columns={sortingColumns}
                                    defaultSorted={defaultSorted}
                                    onTableChange={onTableChange}
                                    {...paginationTableProps}
                                />
                            </div>
                            <div className="d-flex justify-content-between">
                                <PaginationTotalStandalone
                                    {...paginationProps}
                                />
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                            </div>
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    )
};

export default DataTable