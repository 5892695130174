import React, {useState} from 'react';
import Highlight from 'react-highlight';
import {Link} from "react-router-dom";
import CopyButton from "../../common/CopyButton";


const VoucherLinkConfigInformation = ({trigger}) => {

    const [helpVisible, setHelpVisible] = useState(false);

    const handleToggleHelpVisible = () => {
        setHelpVisible(!helpVisible)
    };

    const host = process.env.REACT_APP_PAGES_HOST;
    const url = host + "/p/cl/"+trigger.id;

    const urlSnippet = `\

<!-- use this link -->
<a href="`+url+`">GET THE VOUCHER</a>
 `;

    const urlParameterSnippet = `\

<!-- use this link if you want to collect customer data -->
<a href="`+url+`?id=test@example.com&name=Full Name">GET THE VOUCHER</a>
 `;

    return (
        <div className="">
            <div>
                <div className="text-muted text-small">A link to distribute voucher codes.</div>
                <div className="text-small">Redeem URL:&nbsp;<span className="text-decoration-underline text-primary">{url}</span>
                    <CopyButton className="ml-4" text={url} label="Copy"/>
                </div>
            </div>

            <div className="mt-2">
                <div className="text-small">
                    <i className="far fa-question-circle mr-1 text-success"></i><Link to="#" onClick={handleToggleHelpVisible}>How to use a link to distribute codes?</Link>
                </div>
            </div>

            <div className={helpVisible ? '' : 'd-none'}>
                <div className="text-small mt-4 ">
                    Copy this URL into an email, website or social media post. If your customer opens the link
                    he or she will be redirected to a page containing a personal voucher code.
                </div>
                <div className="text-small mt-4">
                    <Highlight language="javascript" className="rounded-2">{urlSnippet}</Highlight>
                    <div className="mt-1">
                        <CopyButton text={urlSnippet}/>
                    </div>
                </div>
                <div className="text-small mt-4 ">
                    If you want to store the name and email of the customer redeeming the code you can add these
                    details as <code>id</code> and <code>name</code> parameter.
                </div>
                <div className="text-small mt-4">
                    <Highlight language="javascript" className="rounded-2">{urlParameterSnippet}</Highlight>
                    <div className="mt-1">
                        <CopyButton text={urlParameterSnippet}/>
                    </div>
                </div>
                <div className="mt-4 alert alert-info text-small" role="alert">
                    <i className="fa fa-exclamation-triangle mr-1"></i>
                    Depending on the email or chatbot service you are using the name and email are available as placeholder or merge fields<br/>
                    <br/>
                    Mailchimp Example:<br/>
                    {url}?id=<code>*|EMAIL|*</code>&name=<code>*|FNAME|*</code>
                </div>
            </div>
        </div>
    )

};

export default VoucherLinkConfigInformation