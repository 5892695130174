import axios from "axios";

const codeListApi = {

    create(codeList) {
        return axios({
            indicatorId: 'create-codelist',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists',
            data: codeList
        })
    },
    update(codeList) {
        return axios({
            indicatorId: 'update-codelist',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + codeList.id,
            data: codeList
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-codelist',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + id,
        })
    },
    find(accountId, configurationId, sort) {
        return axios({
            indicatorId: 'load-codelist',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists',
            params: {
                accountId: accountId,
                configurationId: configurationId,
                sort: sort
            }
        })
    },
    addCodes(id, codes) {
        return axios({
            indicatorId: 'add-codelist-codes',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + id + '/codes',
            data: {
                id: id,
                codes: codes
            }
        })
    },
    deleteCodes(id, codeIds) {
        return axios({
            indicatorId: 'delete-codelist-codes',
            method: 'delete',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + id + '/codes',
            data: {
                id: id,
                codeIds: codeIds
            }
        })
    },
    loadCodes(id, sort, page, size) {
        return axios({
            indicatorId: 'load-codelist-codes',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + id + '/codes',
            params: {
                sort: sort,
                page: page - 1,
                size: size
            }
        })
    },
    exportContacts(id) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return axios({
            indicatorId: 'export-codelist-contacts',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/codelists/' + id + '/export?timezone=' + timezone
        })
    },


};

export default codeListApi;