import axios from "axios";

const organizationApi = {
    update(
        organization
    ) {
        return axios({
            indicatorId: 'update-organization',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/organizations/' + organization.id,
            data: organization
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-organization',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/organizations/' + id,
        })
    },
    usage(id) {
        return axios({
            indicatorId: 'load-organization-usage',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/organizations/' + id + '/usage',
        })
    },

};

export default organizationApi;