import React, {useEffect, useState} from 'react';
import organizationApi from "./organizationApi";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import LoadingButton from "../form/LoadingButton";
import PlanPanel from "./PlanPanel";
import UsageQuotaPanel from "./UsageQuotaPanel";
import PaymentCustomerPortalButton from "../payment/PaymentCustomerPortalButton";

const OrganizationInformationForm = ({organizationId}) => {

    const [organization, setOrganization] = useState({
        name: '',
        invoiceEmail: '',
        invoiceAddress: '',
        notificationEmail: '',
    });

    useEffect(() => {
        organizationApi
            .load(organizationId)
            .then((response) => {
                setOrganization(response.data)
            })
    }, [organizationId]);

    const handleOrganizationSave = (form) => {
        const name = form.name;
        const invoiceEmail = form.invoiceEmail;
        const notificationEmail = form.notificationEmail;
        const invoiceAddress = form.invoiceAddress;
        organizationApi
            .update({
                ...organization,
                name,
                notificationEmail,
                invoiceEmail,
                invoiceAddress
            })
            .then((response) => {
                setOrganization(response.data)
            })
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('This field is required.'),
        notificationEmail: Yup.string()
            .email('Please enter a valid email.')
            .required('This field is required.'),
    });

    return (
        <div>

            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchema}
                initialValues={{
                    invoiceAddress: organization.invoiceAddress,
                    invoiceEmail: organization.invoiceEmail,
                    notificationEmail: organization.notificationEmail,
                    name: organization.name
                }}
                onSubmit={handleOrganizationSave}
            >
                { props => (
                    <Form>
                        <div>
                            <h5 className="">Company Details</h5>
                            <div className="form-group">
                                <label htmlFor="name">Company Name</label>
                                <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="notificationEmail">Notification Email Address</label>
                                <Field
                                    name="notificationEmail"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                                <small className="form-text text-muted">If we have updates or information for you, we will send it to this address.
                                </small>
                            </div>

                            <div className="form-group">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-organization"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <h5 className="">Billing Settings</h5>
            <div className="form-group">
                <div className="d-flex justify-content-between">
                    <label>Current Plan</label>
                </div>
                <PlanPanel plan={organization.plan} />
            </div>

            <div className="form-group">
                <div className="d-flex justify-content-between">
                    <label>Invoices and Payment Settings</label>
                </div>
                <PaymentCustomerPortalButton/>
            </div>

            {window.toggles.FT_USAGE_LIMIT &&  (
            <div>

            <h5 className="">Usage</h5>
            <UsageQuotaPanel organizationId={organizationId}/>

            </div>
            )}

            <div className="text-hide">TODO: invoice list and download</div>
        </div>
    )
};

export default OrganizationInformationForm