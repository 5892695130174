import React from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import Footer from "../footer/Footer";
import ConfigurationInformationPanel from "./ConfigurationInformationPanel";
import auth from "../auth/auth";
import TriggerSelectionPanel from "../trigger/TriggerSelectionPanel";
import ActionSelectionPanel from "../action/ActionSelectionPanel";
import Page from "../common/Page";

const ConfigurationEditPage = (props) => {

    const configurationId = props.match.params.id;
    const accountId = auth.data().accountId;

    return <Page>
        <PrivateNavigation/>
        <section className="section">

            <ConfigurationInformationPanel accountId={accountId} configurationId={configurationId}/>
            <TriggerSelectionPanel accountId={accountId} configurationId={configurationId}/>
            <ActionSelectionPanel accountId={accountId} configurationId={configurationId}/>
        </section>
        <Footer/>
    </Page>
};

export default ConfigurationEditPage