import axios from "axios";

const accountApi = {
    update(account) {
        return axios({
            indicatorId: 'update-account',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts/' + account.id,
            data: account
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-account',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts/' + id,
        })
    },
    find(organizationId) {
        return axios({
            indicatorId: 'load-account',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts?organizationId=' + organizationId,
        })
    },
    loadIntegrations(id) {
        return axios({
            indicatorId: 'load-integrations',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts/' + id + '/integrations',
        })
    },
    updateIntegrations(id, type, integration) {
        return axios({
            indicatorId: 'update-integration',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts/' + id + '/integrations/' + type,
            data: integration
        })
    },
    validateIntegrations(id, type, integration) {
        return axios({
            indicatorId: 'validate-integration',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/accounts/' + id + '/integrations/' + type + '/validate',
            data: integration
        })
    },

};

export default accountApi;