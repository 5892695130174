import React, {useState} from 'react';
import Modal from "../modal/Modal";
import LoadingButton from "../form/LoadingButton";
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import * as Yup from "yup";
import configurationApi from "./configurationApi";
import auth from "../auth/auth";
import {TriggerType} from "./configuration";
import LanguageSelect from "./LanguageSelect";

const NewConfigurationPanel = ({className}) => {

    const [modalVisible, setModalVisible] = useState(false);
    // manychat, klicktipp
    const [template, setTemplate] = useState("manychat");
    const accountId = auth.data().accountId;

    const handleToggleModalVisible = () => {
        setModalVisible(!modalVisible)
    };

    const handleChangeTemplate = (value) => {
        setTemplate(value)
    };


    const handleCreateConfig = (form) => {

        const configuration = {
            accountId: accountId,
            name: form.name,
            marketplace: form.marketplace,
            language: form.language
        };
        configurationApi
            .create(configuration, template)
            .then((response) => {
                handleToggleModalVisible();
                window.location.href = "/configurations/"+ response.data.id;
            });
    };

    const validationSchemaProfile = Yup.object().shape({
        name: Yup.string()
            .required('This field is required.')
    });

    return (
        <div>
            <button className={"btn btn-outline-success " + className} onClick={handleToggleModalVisible}>
                Create Configuration
            </button>


            <Modal isOpen={modalVisible} onClose={handleToggleModalVisible} additionalClassNamePrefix="new-configuration">
                <Formik
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validationSchema={validationSchemaProfile}
                    initialValues={{
                        name: "",
                        language : "en",
                        marketplace: "US"
                    }}
                    onSubmit={handleCreateConfig}
                >
                    { props => (
                        <Form>

                            <div className="modal-header">
                                <h5 className="modal-title">Create Configuration</h5>
                                <button type="button" className="close" onClick={handleToggleModalVisible}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group d-none">
                                    <span className="text-small text-muted">
                                        Create configuration for account <b>{accountId}</b>.
                                    </span>
                                </div>

                                <div className="form-group">
                                    <label>
                                        How do you want to distribute your voucher code?
                                    </label>
                                </div>

                                <div className="">

                                    {window.toggles.FT_MANYCHAT_APP &&  (
                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("manychat_app")}>
                                        <div className={"p-3 border rounded " + (template === 'manychat_app' ? "border-success" : "")}>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.MANYCHAT_APP_ENDPOINT+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">ManyChat App</div>
                                            </div>
                                            <div className="text-small">Use the 'ManyChat Coupon Action' to distribute codes in flows</div>
                                        </div>
                                    </div>

                                    )}

                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("manychat")}>
                                        <div className={"p-3 border rounded " + (template === 'manychat' ? "border-success" : "")}>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.MANYCHAT_ENDPOINT+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">ManyChat Dynamic Content</div>
                                            </div>
                                            <div className="text-small">Distribute codes via Facebook chat bot inside your ManyChat flows</div>
                                        </div>
                                    </div>

                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("chatfuel")}>
                                        <div className={"p-3 border rounded " + (template === 'chatfuel' ? "border-success" : "")}>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.CHATFUEL_ENDPOINT+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">Chatfuel</div>
                                            </div>
                                            <div className="text-small">Distribute codes via Facebook chat bot inside your Chatfuel blocks</div>
                                        </div>
                                    </div>


                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("klicktipp")}>
                                        <div className={"p-3 border rounded " + (template === 'klicktipp' ? "border-success" : "")}>
                                            <div className="font-weight-bold"></div>
                                            <div className="font-weight-bold"></div>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.KLICKTIPP_ENDPOINT+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">Klick-Tipp</div>
                                            </div>
                                            <div className="text-small">Send codes per email to Klick-Tipp subscribers</div>
                                        </div>
                                    </div>

                                    <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("form")}>
                                        <div className={"p-3 border rounded " + (template === 'form' ? "border-success" : "")}>
                                            <div className="font-weight-bold"></div>
                                            <div className="font-weight-bold"></div>
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 border rounded">
                                                    <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.VOUCHER_FORM+".png"}/>
                                                </div>
                                                <div className="font-weight-bold">Pop-Up/Form</div>
                                            </div>
                                            <div className="text-small">Add a pop-up form to your website and send codes per email</div>
                                        </div>
                                    </div>

                                    {window.toggles.FT_VOUCHER_LINK &&  (
                                        <div className="mt-1 cursor-pointer" onClick={() => handleChangeTemplate("link")}>
                                            <div className={"p-3 border rounded " + (template === 'link' ? "border-success" : "")}>
                                                <div className="font-weight-bold"></div>
                                                <div className="font-weight-bold"></div>
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2 border rounded">
                                                        <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+TriggerType.VOUCHER_LINK+".png"}/>
                                                    </div>
                                                    <div className="font-weight-bold">Redeem Link</div>
                                                </div>
                                                <div className="text-small">Distribute codes thru a simple link to your customers</div>
                                            </div>
                                        </div>
                                    )}

                                </div>


                                <div className="form-group mt-3 ">
                                    <label htmlFor="firstName">Configuration Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                    <small className="form-text text-muted">
                                        This name will be not visible to the end user.
                                    </small>
                                </div>
                                <div className="form-group mt-3 ">
                                    <label htmlFor="firstName">Language</label>
                                    <Field
                                        name="language"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={LanguageSelect}/>
                                    <small className="form-text text-muted">
                                        Language used to create mail, page and chat bot message templates. <br/>Basically the
                                        language of your customers.
                                    </small>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <LoadingButton className="btn btn-outline-success btn-sm"
                                               indicatorId="create-configuration"
                                               label="Create"/>
                                <button type="button"
                                        className="btn btn-light"
                                        onClick={handleToggleModalVisible}>Cancel</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
};

export default NewConfigurationPanel