import React, {useState} from 'react';
import "react-table/react-table.css";
import * as Yup from "yup";
import LoadingButton from "../form/LoadingButton";
import {Form, Formik} from "formik";
import ChatFuelMessageEditor from "./ChatFuelMessageEditor";
import ManyChatMessageEditor from "./ManyChatMessageEditor";

const _ = require('lodash');

const ChatBotResponseConfigEditPanel = ({
                               actionId,
                               action,
                               onUpdate = () => {
                               }
                           }) => {

    const [codeResponse, setCodeResponse] = useState(_.cloneDeep(action.config.codeResponse));
    const [noCodeResponse, setNoCodeResponse] = useState(_.cloneDeep(action.config.noCodeResponse));
    const [deactivatedResponse, setDeactivatedResponse] = useState(_.cloneDeep(action.config.deactivatedResponse));
    const {config, type} = action;

    const handleUpdate = (form) => {
        onUpdate({
            ...action,
            config: {
                ...config,
                codeResponse: codeResponse,
                noCodeResponse: noCodeResponse,
                deactivatedResponse: deactivatedResponse
            }
        })
    };

    const validationSchemaProfile = Yup.object().shape({});

    return (
        <>
            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchemaProfile}
                initialValues={{}}
                onSubmit={handleUpdate}
            >
                {props => (
                    <Form>
                        <div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Code Response:</label>
                                    {{
                                        CHATFUEL_RESPONSE: <ChatFuelMessageEditor response={codeResponse} onChange={setCodeResponse}/>,
                                        MANYCHAT_RESPONSE: <ManyChatMessageEditor response={codeResponse} onChange={setCodeResponse}/>,
                                    }[type]}
                                    <small className="form-text text-muted mt-2">
                                        This response will be send if a code is available.
                                    </small>

                                    <div className="small text-muted mt-4">
                                        Available Placeholders:<br/>
                                        <span dangerouslySetInnerHTML={{__html: '{{code}}'}}></span> - Code as text
                                    </div>
                                </div>
                                <div className="form-group col-4">
                                    <label>Limit Response:</label>
                                    {{
                                        CHATFUEL_RESPONSE: <ChatFuelMessageEditor response={noCodeResponse} onChange={setNoCodeResponse}/>,
                                        MANYCHAT_RESPONSE: <ManyChatMessageEditor response={noCodeResponse} onChange={setNoCodeResponse}/>,
                                    }[type]}
                                    <small className="form-text text-muted">
                                        This response will be send if a the code list is empty or the limit is reached.
                                    </small>
                                </div>
                                <div className="form-group col-4">
                                    <label>Deactivated Response:</label>
                                    {{
                                        CHATFUEL_RESPONSE: <ChatFuelMessageEditor response={deactivatedResponse} onChange={setDeactivatedResponse}/>,
                                        MANYCHAT_RESPONSE: <ManyChatMessageEditor response={deactivatedResponse} onChange={setDeactivatedResponse}/>,
                                    }[type]}
                                    <small className="form-text text-muted">
                                        This response will be send if the configuration is not active.
                                    </small>
                                </div>
                            </div>

                            <div className="form-group mt-4">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-action"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>

    )
};

export default ChatBotResponseConfigEditPanel