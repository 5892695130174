import React from 'react'
import './TextInput.css'

function getProperty(obj, prop) {
    prop = prop.split('.');
    for (var i = 0; i < prop.length; i++) {
        if(typeof obj[prop[i]] === 'undefined')
            return null;
        obj = obj[prop[i]];
    }
    return obj;
}

const FormError = ({name,touched,errors}) => {
    const error = getProperty(errors, name);
    const touch = getProperty(touched, name);
    return (
        touch && error && (<div className="text-danger text-small text-left w-100">{error}</div>)
    )
};

export default FormError