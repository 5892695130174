import axios from "axios";

const configurationApi = {
    create(configuration, template) {
        return axios({
            indicatorId: 'create-configuration',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/configurations/'+template,
            data: configuration
        })
    },
    update(configuration) {
        return axios({
            indicatorId: 'update-configuration',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/configurations/' + configuration.id,
            data: configuration
        })
    },
    delete(configuration) {
        return axios({
            indicatorId: 'delete-configuration',
            method: 'delete',
            url: process.env.REACT_APP_API_HOST + '/api/v1/configurations/' + configuration.id,
            data: {}
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-configuration',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/configurations/' + id,
        })
    },
    find(accountId, sort) {
        return axios({
            indicatorId: 'load-configuration',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/configurations',
            params: {
                accountId: accountId,
                sort: sort
            }
        })
    },

};

export default configurationApi;