import axios from "axios";

const actionApi = {

    create(action) {
        return axios({
            indicatorId: 'create-action',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/actions',
            data: action
        })
    },
    update(action) {
        return axios({
            indicatorId: 'update-action',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/actions/' + action.id,
            data: action
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-action',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/actions/' + id,
        })
    },
    delete(action) {
        return axios({
            indicatorId: 'delete-action',
            method: 'delete',
            url: process.env.REACT_APP_API_HOST + '/api/v1/actions/' + action.id,
        })
    },
    find(configurationId) {
        return axios({
            indicatorId: 'load-action',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/actions',
            params: {
                configurationId: configurationId
            }
        })
    }
};

export default actionApi;