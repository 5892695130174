import React, {useState} from 'react';
import {TriggerType, TriggerTypeNames} from "../configuration/configuration";
import ManyChatEndpointConfigInformation from "./ManyChatEndpointConfigInformation";
import KlickTippEndpointConfigInformation from "./KlickTippEndpointConfigInformation";
import ModalConfirmation from "../modal/ModalConfirmation";
import triggerApi from "./triggerApi";
import VoucherFormConfigInformation from "./VoucherFormConfigInformation";
import Modal from "../modal/Modal";
import VoucherFormConfigEditPanel from "./VoucherFormConfigEditPanel";
import ChatFuelEndpointConfigInformation from "./ChatFuelEndpointConfigInformation";
import AmzleadInfo from "./amzlead/AmzleadInfo";
import VoucherLinkConfigInformation from "./voucherlink/VoucherLinkConfigInformation";
import ManyChatAppEndpointConfigInformation from "./manychatapp/ManyChatAppEndpointConfigInformation";

const TriggerListItem = ({trigger, accountId, onUpdate = () => {}, onRemove = () => {}}) => {

    const [modalVisible, setModalVisible] = useState(false);

    const [editModalVisible, setEditModalVisible] = useState(false);

    const handleToggleEditModalVisible = () => {
        setEditModalVisible(!editModalVisible)
    };

    const handleEdit = () => {
        setEditModalVisible(true);
    };

    const loading = trigger.id === undefined;
    const {
        id,
        type,
    } = trigger;

    const handleDelete = (trigger) => {
        triggerApi
            .delete(trigger)
            .then((response) => {
                handleToggleModalVisible();
                onRemove(trigger)
            })
    };

    const handleUpdate = (trigger) => {
        triggerApi
            .update(trigger)
            .then((response) => {
                const updated = response.data;
                onUpdate(updated)
            })
    };

    const handleToggleModalVisible = () => {
        setModalVisible(!modalVisible)
    };

    return (
        <>
            <ModalConfirmation
                isOpen={modalVisible}
                onSubmit={() => handleDelete(trigger)}
                onClose={handleToggleModalVisible}
                buttonLabel="Delete"
                buttonClassName="btn-danger"
                indicatorId="delete-trigger"
                title="Delete Trigger">
                <p>Delete '{TriggerTypeNames[type]}' trigger?</p>
                <div className="alert alert-warning text-small" role="alert">
                    Make sure the related configuration is deactivated and
                    the endpoint is not longer used by any integration.
                </div>
            </ModalConfirmation>


            <Modal
                isOpen={editModalVisible}
                onClose={handleToggleEditModalVisible}
                additionalClassNamePrefix="modal-lg-vj edit-code-list"

            >
                <div className="modal-header">
                    <h5 className="modal-title">Edit Trigger</h5>
                    <button type="button" className="close" onClick={handleToggleEditModalVisible}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {{
                        VOUCHER_FORM: <VoucherFormConfigEditPanel actionId={id} trigger={trigger} onUpdate={handleUpdate}/>,
                        LANDINGPAGE_AMZLEAD_FORM : <AmzleadInfo/>,
                        LANDINGPAGE_AMZLEAD_LINK : <AmzleadInfo/>
                    }[type]}
                </div>
                <div className="modal-footer">
                    <button type="button"
                            className="btn btn-light"
                            onClick={handleToggleEditModalVisible}>Close</button>
                </div>
            </Modal>

            {!loading && (
                <div className="border rounded p-4 mt-4">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="mr-2 border rounded">
                                <img alt="icon" style={{"margin": "2px"}} width="30" src={"/assets/images/integration_"+type+".png"}/>
                            </div>
                            <div>{TriggerTypeNames[type]}</div>
                        </div>
                        <div>
                            {window.toggles.FT_ADD_TRIGGER && (
                                <button
                                    className="ml-1 btn btn-sm btn-outline-secondary"
                                    onClick={() => {handleToggleModalVisible(id)}}>Remove</button>
                            )}
                            {(type === TriggerType.VOUCHER_FORM || type === TriggerType.LANDINGPAGE_AMZLEAD_FORM  || type === TriggerType.LANDINGPAGE_AMZLEAD_LINK) && (
                                <button
                                    className="ml-1 btn btn-sm btn-outline-secondary" onClick={handleEdit}>Edit</button>
                            )}
                        </div>
                    </div>
                    <div className="mt-2">
                        {{
                            MANYCHAT_ENDPOINT: <ManyChatEndpointConfigInformation trigger={trigger}/>,
                            MANYCHAT_APP_ENDPOINT: <ManyChatAppEndpointConfigInformation trigger={trigger} accountId={accountId} />,
                            CHATFUEL_ENDPOINT: <ChatFuelEndpointConfigInformation trigger={trigger}/>,
                            KLICKTIPP_ENDPOINT: <KlickTippEndpointConfigInformation trigger={trigger}/>,
                            VOUCHER_FORM: <VoucherFormConfigInformation trigger={trigger}/>,
                            VOUCHER_LINK: <VoucherLinkConfigInformation trigger={trigger}/>,
                            LANDINGPAGE_AMZLEAD_FORM: <AmzleadInfo/>,
                            LANDINGPAGE_AMZLEAD_LINK: <AmzleadInfo/>
                        }[type]}
                    </div>
                </div>
            )}
            {loading && (
                <div className="mt-4">
                    <p className="bg-loading rounded w-100 d-inline-block">
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                    </p>
                </div>
            )}
        </>
    )
};

export default TriggerListItem