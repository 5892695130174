import React from 'react';
import CopyButton from "../common/CopyButton";

const KlickTippEndpointConfigInformation = ({trigger}) => {

    const url = "https://api.voucherjet.com/api/v1/p/klicktipp/"+trigger.id;
    return (
        <div>
            <div className="text-small">Callback URL:&nbsp;<span className="text-decoration-underline text-primary">{url}</span>
                <CopyButton className="ml-4" text={url} label="Copy"/>
            </div>
            <div className="mt-2 text-small"><i className="far fa-question-circle mr-1 text-success"></i><a href="https://www.voucherjet.com/en/docs/how-to-distribute-unique-voucher-coupon-codes-with-klicktipp">How to connect your Klick-Tipp account with VoucherJet</a></div>
        </div>
    )
};

export default KlickTippEndpointConfigInformation