import React from 'react';
import {Link} from "react-router-dom";
import PublicNavigation from "../navigation/PublicNavigation";
import Footer from "../footer/Footer";
import Page from "../common/Page";

const PostRegistrationPage = () => {

    return <Page>
        <PublicNavigation/>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="container container--mini mt-5 text-center">
                        <h1 className="mb-5 text-center">Thanks!</h1>
                        <p>
                            Thanks for signing up to VoucherJet.<br/>
                            Log in and get started!
                        </p>
                        <div>
                            <Link to="/login" className="btn btn-outline-success btn-sm" >Login</Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default PostRegistrationPage