import React, {useState} from 'react';
import Highlight from 'react-highlight';
import {Link} from "react-router-dom";
import CopyButton from "../common/CopyButton";

const VoucherFormConfigInformation = ({trigger}) => {

    const [helpVisible, setHelpVisible] = useState(false);
    const {headerMarkup, domains} = trigger.config;
    const id = trigger.id;

    const handleToggleHelpVisible = () => {
        setHelpVisible(!helpVisible)
    };

    const handleUpdate = (e) => {
        window.VoucherLib.openPopUp(e.target, trigger.id)
    };


    const jsSnippet = `\

<!-- copy this into your <head> section of your web site -->
<script type="text/javascript">
(function(w,d,s,u,f,a,m){w['VoucherJetObject']=f;w[f]=w[f]||function(){
    (w[f].q=w[f].q||[]).push(arguments)},w[f].l=1*new Date();a=d.createElement(s),
    m=d.getElementsByTagName(s)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://api.voucherjet.com/cdn/dist/voucherjet-1.2.0.js', '_vjt');
</script>
                `;

    const buttonSnippet = `\

<!-- you can use a link -->
<a class="voucherjet-cta" data-form-id="`+trigger.id+`">GET THE VOUCHER</a>
<!-- or a button -->
<button class="voucherjet-cta" data-form-id="`+trigger.id+`">GET THE VOUCHER</button>
                `;



    return (
        <div className="">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="text-muted text-small">Voucher Pop-Up or HTML Form.</div>
                        <div className="text-muted text-small">Header: '{headerMarkup}'</div>
                        {domains && (
                            <div className="text-muted text-small">This pop-up will work for these domains: {domains}</div>
                        )}
                    </div>
                    <div><button
                        onClick={handleUpdate}
                        className="ml-1 btn btn-sm btn-outline-success"
                        data-form-id={id}>Test Pop-Up</button></div>
                </div>

                <div className="mt-2">
                    <div className="text-small">
                        <i className="far fa-question-circle mr-1 text-success"></i><Link to="#" onClick={handleToggleHelpVisible}>How to use the voucher popup on your website?</Link>
                    </div>
                </div>

                <div className={helpVisible ? '' : 'd-none'}>
                    <div className="text-small">
                        <br/>
                        1) Copy & page the JavaScript snippet and add it to your HTML
                    </div>
                    <div className="text-small mt-4">
                        <Highlight language="javascript" className="rounded-2">{jsSnippet}</Highlight>
                        <div className="mt-1">
                            <CopyButton text={jsSnippet}/>
                        </div>
                    </div>

                    <div className="text-small mt-4 ">
                        2) Add a link or button to your page that opens the popup on click:
                    </div>
                    <div className="text-small mt-4">
                        <Highlight language="javascript" className="rounded-2">{buttonSnippet}</Highlight>
                        <div className="mt-1">
                            <CopyButton text={buttonSnippet}/>
                        </div>
                    </div>
                    <div className="mt-2 alert alert-warning text-small" role="alert">
                        <i className="fa fa-exclamation-triangle mr-1"></i> Please make sure the <code>data-form-id</code> is set to <code>{id}</code>
                    </div>
                </div>
        </div>
    )
};

export default VoucherFormConfigInformation