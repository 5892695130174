import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {indicatorService} from "../loadingindicator/IndicatorService";
import './LoadingButton.css'

class LoadingButton extends Component {

    static propTypes = {
        indicatorId: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.string.isRequired,
        iconLeft: PropTypes.string,
        iconRight: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        className: PropTypes.string,
        confirmIconClassName: PropTypes.string,
        loadingIconClassName: PropTypes.string,
        onClick: PropTypes.func,
        badge: PropTypes.number,
        badgeClassName: PropTypes.string
    };

    static defaultProps = {
        type: "submit",
        confirmIconClassName: "fa-check",
        loadingIconClassName: "fa-spin fa-spinner",
        disabled: false,
        onClick: (entry) => {
        }
    };

    callback = undefined;

    state = {
        loading: false,
        confirming: false,
        submitting: false
    };

    componentDidMount = () => {
        if(this.props.indicatorId){
            this.indicatorId = this.props.indicatorId;
            indicatorService.register(this)
        }
    };

    componentWillUnmount() {
        clearTimeout(this.callback);
        if(this.props.indicatorId) {
            indicatorService.unregister(this)
        }
    }

    show = () => {
        if (this.state.submitting) {
            this.setState({loading: true});
        }
    };

    hide = () => {
        this.setState({
            loading: false,
            confirming: this.state.submitting,
            submitting: false
        });

        this.callback = setTimeout(() => {
            this.setState({
                confirming: false
            });
        }, 1000)
    };

    handleClick = () => {
        this.setState({
            submitting: true
        }, this.props.onClick);
    };

    render() {
        const {
            disabled,
            label,
            iconLeft,
            iconRight,
            name,
            type,
            className,
            badgeClassName,
            confirmIconClassName,
            loadingIconClassName,
            badge
        } = this.props;

        const {loading, confirming} = this.state;

        const classes = classNames(
            "btn",
            className
        );

        const badgeClasses = classNames(
            "loading-button-badge",
            badgeClassName
        );

        return (
            <button type={type} onClick={this.handleClick} name={name} disabled={loading || disabled}
                    className={classes}>
                {loading && (
                    <span style={{width: "24px", display: "inline-block"}}><i
                        className={"fa fas " + loadingIconClassName}/>&nbsp;</span>
                )}
                {confirming && (
                    <span style={{width: "24px", display: "inline-block"}}><i
                        className={"fa fas " + confirmIconClassName}/>&nbsp;</span>
                )}
                {!loading && !confirming && (
                    <span style={{width: "24px", display: "inline-block"}}/>
                )}
                <span>
                    {iconLeft && (
                        <span><i className={"fa " + iconLeft}/>&nbsp;</span>
                    )}
                    {label}
                    {iconRight && (
                        <span>&nbsp;<i className={"fa " + iconRight}/></span>
                    )}
                </span>
                <span style={{width: "24px", display: "inline-block"}}/>
                {badge > 0 && (
                    <div className={badgeClasses}>{badge}</div>
                )}
            </button>
        );
    }

}

export default LoadingButton
