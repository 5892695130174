import React, {useState} from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import LoadingButton from "../form/LoadingButton";
import TextInput from "../form/TextInput";
import authApi from "./authApi";
import PublicNavigation from "../navigation/PublicNavigation";
import {CheckboxBootstrap} from "../form/CheckBox";
import Footer from "../footer/Footer";
import FormError from "../form/FormError";
import Page from "../common/Page";
import queryString from "query-string";

const RegistrationPage = (props) => {

    const [authError, setAuthError] = useState(undefined);

    const handleSignup = (values) => {
        setAuthError(undefined);
        authApi
            .signup(
                values.firstName,
                values.lastName,
                values.consent,
                values.email,
                values.password
            )
            .then(() => {
                window.location.href = "/signup/thankyou";
            })
            .catch((error) => {
                let data = error.response.data;
                setAuthError(data.message);
            })
    };

    const amzlead = queryString.parse(props.location.search).utm_source === "amzlead";

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('This field is required.'),
        lastName: Yup.string()
            .required('This field is required.'),
        password: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.'),
        email: Yup.string()
            .email('Please enter a valid email.')
            .required('This field is required.'),
        consent: Yup.boolean()
            .oneOf([true], 'Please accept terms and conditions'),
    });

    return <Page>
        <PublicNavigation/>
        <section className="section">
            <div className="container">
                {amzlead && (
                    <div className="text-center mt-5">
                        <img height="60" alt="amzlead logo" src="/assets/images/amzlead_logo.png" />
                        <div className="mt-3">
                            <div className="d-flex justify-content-center">
                                <div className="mr-2">is now part of </div>
                                <img height="20" alt="voucherjet logo" src="/assets/images/voucherjet-logo.png" />
                            </div>
                            <div className="mt-4">
                                Get all the features of AMZLEAD plus lots of new ones like chatbot integrations and many more.<br/>
                                Signup and get your AMZLEAD landing page up and running.
                            </div>
                            <div className="mt-3">
                                Need some help? <a className="text-decoration-underline" target="_blank" rel="noreferrer noopener" href="https://www.voucherjet.com/en/docs/how-to-create-a-amazon-landing-page-to-distribute-unique-voucher-coupon-codes" >Read our small guide.</a>
                            </div>
                        </div>

                    </div>
                )}
                <div className="row">
                    <div className="container container--mini mt-5">
                        <h1 className="mb-5 text-center mt-5">Sign Up</h1>




                        {authError && (
                            <div className="alert alert-danger text-small" role="alert">
                                {authError}
                            </div>
                        )}

                        <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validationSchema={validationSchema}
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                consent: false,
                                email: '',
                                password: ''
                            }}
                            onSubmit={handleSignup}
                        >
                            { props => (
                                <Form>
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="firstName">First Name</label>
                                            <Field
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                component={TextInput}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last Name</label>
                                            <Field
                                                name="lastName"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                component={TextInput}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field
                                                name="email"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                component={TextInput}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                placeholder=""
                                                component={TextInput}/>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div>
                                                <Field
                                                    component={CheckboxBootstrap}
                                                    name="consent"
                                                    id="consent"
                                                    hideError={true}
                                                />
                                            </div>
                                            <div >
                                                <div className="text-muted text-small">
                                                    By checking this box, you are creating a VoucherJet account, and you agree to
                                                    VoucherJet's <a className="text-decoration-underline" href="https://www.voucherjet.com/en/terms" target="_blank" >Terms &amp; Conditions</a> and <a className="text-decoration-underline" href="https://www.voucherjet.com/en/privacy" target="_blank">Privacy Policy</a>.
                                                    <br/>
                                                </div>
                                                <div>
                                                    <FormError name="consent" errors={props.errors} touched={props.touched}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <LoadingButton className="btn btn-outline-success  btn-block mb-4"
                                                           indicatorId="signup"
                                                           label="Sign Up"/>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>



                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default RegistrationPage