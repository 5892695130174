import React from 'react'
import './TextInput.css'
import FormError from "./FormError";

const Label = ({error, children, ...props}) => {
    return <label {...props}>{children}</label>
};

function getProperty(obj, prop) {
    prop = prop.split('.');
    for (var i = 0; i < prop.length; i++) {
        if(typeof obj[prop[i]] === 'undefined')
            return null;
        obj = obj[prop[i]];
    }
    return obj;
}

const TextInput = ({
                       field: {name, ...field}, // { name, value, onChange, onBlur }
                       form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                       className,
                       label,
                       hideError,
                       ...props
                   }) => {
    const error = getProperty(errors, name);
    const touch = getProperty(touched, name);
    return (
        <React.Fragment>
            {label && <Label htmlFor={name} error={error}>{label}</Label>}
            <input
                autoComplete={'off'}
                id={name}
                className={
                    error && touch ? (
                        'form-control text-input is-invalid ' + className
                    ) : (
                        'form-control text-input'  + className
                    )
                }
                type="text"
                {...field}
                {...props}
            />
            {!hideError && <FormError name={name} errors={errors} touched={touched}/>}
        </React.Fragment>
    )
}

export default TextInput