import React from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import Footer from "../footer/Footer";
import UserProfileForm from "./UserProfileForm";
import OrganizationInformationForm from "./OrganizationInformationForm";
import AccountInformationForm from "./AccountInformationForm";
import auth from "../auth/auth";
import Page from "../common/Page";
import ApiIntegrationForm from "./ApiIntegrationForm";

const SettingsEditPage = () => {

    const organizationId = auth.data().organizationId;
    const userId = auth.data().userId;
    const accountMode = auth.data().accountMode;
    const singleAccount = accountMode === "SINGLE_ACCOUNT";
    const accountId = singleAccount ? auth.data().accountId : undefined;

    return <Page>
        <PrivateNavigation/>
        <section className="section">
            <div className="container mt-5">
                <h1>Settings</h1>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md">
                        <h5>User Profile</h5>
                    </div>
                    <div className="col-md">
                        <UserProfileForm userId={userId}/>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md">
                        <h5>Company &amp; Billing</h5>
                    </div>
                    <div className="col-md">
                        <OrganizationInformationForm organizationId={organizationId}/>
                    </div>
                </div>
            </div>

            {singleAccount && (
                <div className="container mt-5" id="account">
                    <div className="row">
                        <div className="col-md">
                            <h5>Account</h5>
                        </div>
                        <div className="col-md">
                            <AccountInformationForm organizationId={organizationId} accountId={accountId}/>
                        </div>
                    </div>
                </div>
            )}

            <div className="container mt-5" id="integrations">
                <div className="row">
                    <div className="col-md">
                        <h5>Integrations</h5>
                    </div>
                    <div className="col-md">
                        <ApiIntegrationForm accountId={accountId}/>
                    </div>
                </div>
            </div>

            <div className="mt-5">&nbsp;</div>

        </section>
        <Footer/>
    </Page>
};

export default SettingsEditPage