import axios from "axios";

const userApi = {
    update(
        id,
        email,
        firstName,
        lastName
    ) {
        return axios({
            indicatorId: 'update-user',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/users/' + id,
            data: {
                firstName: firstName,
                lastName: lastName,
                email: email
            }
        })
    },
    updatePassword(
        id,
        currentPassword,
        newPassword
    ) {
        return axios({
            indicatorId: 'update-password',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/users/' + id + '/pw',
            data: {
                currentPassword: currentPassword,
                newPassword: newPassword
            }
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-user',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/users/' + id,
        })
    },

};

export default userApi;