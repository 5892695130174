import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import codeListApi from "./codeListApi";
import CodeListListItem from "./CodeListListItem";
import Modal from "../modal/Modal";
import CodeListEditPanel from "./CodeListEditPanel";

const CodeListPanel = ({
       configurationId,
       accountId,
       codeListId,
       onChange = () => {},
       onAdd = () => {},
       onRemove = () => {}
}) => {

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModeCodeUpload, setEditModeCodeUpload] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({value: "", name: ""});
    const [selectedCodeListId, setSelectedCodeListId] = useState();
    const [codeList, setCodeList] = useState({});

    // set initial id as selected
    useEffect(() => {
        if(codeListId) {
            setSelectedCodeListId(codeListId)
        }
    }, [codeListId]);

    // load all options
    useEffect(() => {
        if(accountId){
            codeListApi
                .find(accountId)
                .then((response) => {
                    setOptions(response.data.map((cl) => {
                        return {
                            value: cl.id,
                            label: cl.name
                        }
                    }));
                })
        }
    }, [accountId]);


    // load selected on id change
    useEffect(() => {
        if(selectedCodeListId){
            codeListApi
                .load(selectedCodeListId)
                .then((response) => {
                    handleSelectedCodeList(response.data)
                    if (selectedCodeListId !== codeListId) {
                        onChange(response.data);
                    }
                })
        }
    }, [selectedCodeListId, codeListId, onChange]);

    const handleSelectedCodeList = (cl) => {
        setCodeList(cl);
        setSelectedOption({
            value: cl.id,
            label: cl.name
        })
    };

    const handleChange = (selectedOption) => {
        setSelectedCodeListId(selectedOption.value);
    };

    const handleToggleEditModalVisible = () => {
        setEditModalVisible(!editModalVisible)
    };

    const handleEditCodeList = (codeListId) => {
        setEditModeCodeUpload(false);
        setEditModalVisible(true);
    };

    const handleUploadCodes = (codeListId) => {
        setEditModeCodeUpload(true);
        setEditModalVisible(true);
    };

    return (
        <div className="container mt-2">
            <div className="">
                    <h5>Code List</h5>
                    <span className="text-small text-muted">A 'Code List' contains the unique codes that will be distributed for this configuration.</span>
            </div>

            <div className="d-none">
                <div className="form-group w-25">
                    <label>Selected Code List</label>
                    <Select
                        name="type"
                        value={selectedOption}
                        onChange={(option) => {
                            handleChange(option);
                        }}
                        options={options}
                        className='react-select-container w-100'
                        classNamePrefix="react-select"
                    />
                </div>
            </div>

            <CodeListListItem codeList={codeList} onEdit={handleEditCodeList} onUpload={handleUploadCodes}/>


            <Modal
                isOpen={editModalVisible}
                onClose={handleToggleEditModalVisible}
                additionalClassNamePrefix="modal-lg-vj edit-code-list"

            >
                <div className="modal-header">
                    <h5 className="modal-title">Edit Code List</h5>
                    <button type="button" className="close" onClick={handleToggleEditModalVisible}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <CodeListEditPanel
                        codeListId={selectedCodeListId}
                        onUpdate={handleSelectedCodeList}
                        uploadOnly={editModeCodeUpload}
                    />
                </div>
                <div className="modal-footer">
                    <button type="button"
                            className="btn btn-light"
                            onClick={handleToggleEditModalVisible}>Close</button>
                </div>
            </Modal>
        </div>
    )
};

export default CodeListPanel