import React from 'react';
import ApiKeyLabel from "./ApiKeyLabel";

const ApiIntegrationForm = ({accountId}) => {

    return (
        <div>
            <h5 className="">API</h5>
            <div className="form-group mb-5">
                <div className="d-flex justify-content-between">
                    <label htmlFor="senderAddress">Api Key</label>
                </div>
                <ApiKeyLabel accountId={accountId}/>
                <small className="form-text text-muted">The secret key gives other tools access to your account via API.
                </small>
            </div>
        </div>
    )
};

export default ApiIntegrationForm