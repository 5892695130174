import React from 'react';
import BrowserView from "../BrowserView";

const CodePageConfigInformation = ({config}) => {

    const {codePage} = config;
    const {markup, title} = codePage;

    return (
        <div className="">
            <div className="text-muted text-small">The page showing the personal codes.</div>
            <div className="text-muted text-small">Page title:&nbsp;'{title}'</div>
            <div className="mt-3">
                <BrowserView markup={markup}/>
            </div>
        </div>
    )
};

export default CodePageConfigInformation
