const auth = {


    userData: {
        email: "",
        userId: undefined,
        organizationId: undefined,
        accountMode: undefined,
        accountId: undefined,
        token: undefined
    },

    isAuthenticated() {
        return this.data().token !== undefined;
    },

    data() {
        this.load();
        return this.userData;
    },

    authenticate(email, userId, organizationId, token, accountMode, accountId) {
        this.userData.email = email;
        this.userData.userId = userId;
        this.userData.organizationId = organizationId;
        this.userData.accountMode = accountMode;
        this.userData.accountId = accountId;
        this.userData.token = token;
        this.update();
    },

    load() {
        let stored = localStorage.getItem('user-v1');
        if (stored) {
            this.userData = JSON.parse(stored);
        } else {
            this.userData = {
                email: "",
                userId: undefined,
                organizationId: undefined,
                accountMode: undefined,
                accountId: undefined,
                token: undefined
            }
        }
    },

    update() {
        localStorage.setItem('user-v1', JSON.stringify(this.userData));
    },

    signout() {
        localStorage.removeItem('user-v1');
        this.userData = {
            email: "",
            userId: undefined,
            organizationId: undefined,
            accountMode: undefined,
            accountId: undefined,
            token: undefined
        };
    }
};


export default auth;