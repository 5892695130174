import React, {useState} from 'react';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import TextInput from "../../form/TextInput";
import HtmlEditor from "../HtmlEditor";
import LoadingButton from "../../form/LoadingButton";

const PageConfigForm = ({className="", pageConfig, onUpdate}) => {

    const {markup, title} = pageConfig;
    const [editorMarkup, setEditorMarkup] = useState(markup);

    const handleSubmit = (form) => {
        const updatedPageConfig = {
            ...pageConfig,
            title: form.title,
            markup: editorMarkup
        };
        onUpdate(updatedPageConfig)
    };

    const validationSchemaProfile = Yup.object().shape({
        title: Yup.string()
            .required('This field is required.')
    });

    return (
        <div className={className}>
            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchemaProfile}
                initialValues={{
                    title: title
                }}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>
                        <div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Page Title:</label>
                                    <Field
                                        name="title"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Page Content:</label>
                                    <HtmlEditor html={markup} onChange={setEditorMarkup}/>
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-action"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>

                )}
            </Formik>
        </div>
    )
};

export default PageConfigForm