
const DateLabel = ({date}) => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };
    return date ? new Date(date).toLocaleString('us-EN', options) : "-";
};

export default DateLabel
