import React from 'react';
import Count from "../configuration/Count";
import CodeListLabel from "./CodeListLabel";

const CodeListListItem = ({
                              codeList,
                              onEdit = () => {},
                              onUpload = () => {}
}) => {

    const loading = codeList.id === undefined;
    const {
        id,
        name,
        type,
        size,
        limitPerUser,
        limitDistribution,
        limit,
        deliveredInLimit,
        delivered,
        viewed,
        used
    } = codeList;
    const remainingInLimit = limit - deliveredInLimit;

    return (
        <>
            {!loading && (
                <div className="border rounded p-4 mt-4">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex justify-content-start align-items-center">
                                <CodeListLabel type={type}/>
                                <div className="font-weight-bold ml-1">- {name}</div>
                            </div>

                            <div className="text-muted small mt-2">{size - delivered} of {size} codes remaining in total.</div>
                            {limitDistribution && (remainingInLimit > 0) && (
                                <div className="text-muted small">{remainingInLimit} of {limit} codes remaining in daily limit.</div>
                            )}
                            {limitDistribution && (remainingInLimit <= 0) && (
                                <div className="text-warning small">Daily limit of {limit} is reached.</div>
                            )}
                            {limitPerUser && (
                                <div className="text-muted small">Limited to 1 code per user.</div>
                            )}
                        </div>
                        <div>
                            <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => {onEdit(id)}}>Edit</button>
                        </div>
                    </div>

                    {size <= 0 && (
                        <div className="text-center">
                            <button className={"btn btn-outline-success "} onClick={() => {onUpload(id)}}>
                                Upload new Codes
                            </button>
                        </div>
                    )}

                    {size > 0 && (
                        <div className="d-flex justify-content-between mt-2 text-small">
                            <div className="text-dark w-25">&nbsp;</div>
                            <div className="text-muted text-uppercase">Size: <b><Count value={size}/></b></div>
                            <div className="text-muted text-uppercase">Delivered: <b><Count value={delivered}/></b></div>
                            <div className="text-muted text-uppercase">Viewed: <b><Count value={viewed}/></b></div>
                            <div className="text-muted text-uppercase">Used: <b><Count value={used}/></b></div>
                        </div>
                    )}

                </div>
            )}
            {loading && (
                <div className="mt-4">
                    <p className="bg-loading rounded w-100 d-inline-block">
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                    </p>
                </div>
            )}
        </>
    )
};

export default CodeListListItem