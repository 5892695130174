import React from 'react';


const AmzleadInfo = () => {


    return (
        <div className="alert alert-danger text-small" role="alert">
            AMZLEAD pages are no longer supported since 2022-12-01. Please make sure you deactivate this
            configuration.
        </div>
    )
};

export default AmzleadInfo