import React, {useEffect, useState} from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import {Link} from "react-router-dom";
import Footer from "../footer/Footer";
import "./DashboardPage.scss"
import NewConfigurationPanel from "../configuration/NewConfigurationPanel";
import auth from "../auth/auth";
import configurationApi from "../configuration/configurationApi";
import ConfigurationListItem from "../configuration/ConfigurationListItem";
import Page from "../common/Page";
import UsageQuotaPanel from "../organization/UsageQuotaPanel";

const DashboardPage = () => {

    const accountId = auth.data().accountId;
    const organizationId = auth.data().organizationId;
    const [configurations, setConfigurations] = useState();
    useEffect(() => {
        configurationApi
            .find(accountId, "lastModified,desc")
            .then((response) => {
                setConfigurations(response.data.slice(0,3))
            })
    }, [accountId]);
    const loading = configurations === undefined;
    const showCreateButton = !loading && configurations.length === 0;

    const handleDelete = (configuration) => {
        configurationApi
            .delete(configuration)
            .then((response) => {
                configurationApi
                    .find(accountId, "name,asc")
                    .then((response) => {
                        setConfigurations(response.data)
                    })
            })
    };

    return <Page>
        <PrivateNavigation/>
        <section className="section">
            <div className="container mt-5">
                <h1>Dashboard</h1>
            </div>
            {!loading && showCreateButton && (
                <div className="container mt-5 text-center d-flex flex-column justify-content-center h-50">
                    <NewConfigurationPanel/>
                    <p className="text-small text-muted mt-3">
                        Create a configuration to distribute voucher codes <br/> via ManyChat, Chatfuel and many other options.
                    </p>
                </div>
            )}
            {!loading && !showCreateButton && (
                <div className="container mt-5">

                    {window.toggles.FT_USAGE_LIMIT &&  (
                    <div>

                    <h5 className="mt-4">Usage Information</h5>
                    <UsageQuotaPanel organizationId={organizationId}/>

                    </div>
                    )}

                    <h5 className="mt-4">Recent Configurations</h5>
                    <div className="mt-4">
                    {configurations.map((item, key) =>
                        <ConfigurationListItem
                            configuration={item}
                            key={item.id}
                            onRemove={handleDelete}
                        />
                    )}
                    </div>
                    <div>
                        <Link to="/configurations" className="btn btn-link text-small pl-0">All configurations...</Link>
                    </div>
                </div>
            )}

            {loading && (
                <div className="container mt-5 text-center">
                    <p className="bg-loading rounded w-100 d-inline-block">
                        &nbsp;<br/>
                        &nbsp;<br/>
                        &nbsp;<br/>
                    </p>
                </div>
            )}
        </section>
        <Footer/>
    </Page>
};

export default DashboardPage