import React, {useState} from 'react';
import classNames from "classnames";

const TabNavigation = ({tabs,active,onChange}) => {

    const [tab, setTab] = useState(active);

    const handleChange  = (tab) => {
        setTab(tab);
        onChange(tab);
    };

    const navItems = [];
    Object.keys(tabs).map((key, index) => {
        navItems.push({
            id : key,
            label : tabs[key],
            className : classNames("btn nav-link", {"active": tab === key}, {"text-black-50": tab !== key})
        });
        return undefined
    });

    return (
        <ul className="nav nav-tabs mt-3">
            {navItems.map((item, index) =>
            <li key={index} className="nav-item">
                <button className={item.className} onClick={() => handleChange(item.id)}>{item.label}</button>
            </li>
            )}
        </ul>
    )
};

export default TabNavigation