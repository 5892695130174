import React, {Component} from "react";
import {errorService} from "./ErrorService";
import Modal from "../modal/Modal";


class GlobalError extends Component {

    static propTypes = {};

    state = {
        error: {
            message: null,
            title: null,
            modal: false
        }
    };

    componentDidMount = () => {
        errorService.register(this)
    };

    componentWillUnmount() {
        errorService.unregister(this)
    }

    notify = (error) => {
        this.setState({
            error: error
        });
    };

    confirm = () => {
        this.setState({
            error: {
                message: null,
                title: null,
                modal: false
            }
        });
    };

    onConfirm = () => {
        errorService.confirmAll()
    };

    onClose = () => {
        errorService.confirmAll();
        window.location.href = "/login"
    };

    render() {
        const {error} = this.state;
        return error.message && (
            <div>
                {!error.modal && (
                    <div className="container fixed-bottom bg-danger text-light small p-3 z-index-max">
                        <div className="d-flex align-items-center justify-content-between">
                            <span dangerouslySetInnerHTML={{__html: error.message}}></span>
                            <i className="fa fa-times-circle" onClick={this.onConfirm}/>
                        </div>
                    </div>
                )}

                <Modal isOpen={error.modal} onClose={this.onClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">{error.title}</h5>
                    </div>
                    <div className="modal-body">
                        <span dangerouslySetInnerHTML={{__html: error.message}}></span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.onClose}>OK</button>
                    </div>
                </Modal>

            </div>

        )
    };
}

export default GlobalError


