import React, {useEffect, useState} from 'react';
import accountApi from "./accountApi";
import CopyButton from "../common/CopyButton";

const ApiKeyLabel = ({accountId}) => {
    const [apiKey, setApiKey] = useState(undefined);
    const loading = apiKey === undefined;


    useEffect(() => {
        if (accountId) {
            accountApi
                .loadIntegrations(accountId)
                .then((response) => {
                    setApiKey(response.data.API.apiKey);
                })
        }
    }, [accountId]);

    return (
        <div className="d-inline-block">

            {!loading && (
                <div className="d-flex align-items-center">
                <code>{apiKey}</code>
                <div className="ml-4">
                    <CopyButton text={apiKey}></CopyButton>
                </div>
                </div>
            )}

            {loading && (
                    <p className="bg-loading rounded w-50 d-inline-block">
                        &nbsp;<br/>
                    </p>
            )}

        </div>


    )
};

export default ApiKeyLabel