import React, {useState} from 'react';
import "react-table/react-table.css";

import PageConfigForm from "./PageConfigForm";
import TabNavigation from "../../form/TabNavigation";

const CodePageConfigEditPanel = ({
                               actionId,
                               action,
                               onUpdate = () => {
                               }
                           }) => {

    const [tab, setTab] = useState("code");

    const {codePage, noCodePage, deactivatedPage} = action.config;

    const handleUpdateCode = (updatedPageConfig) => {
        onUpdate({
            ...action,
            config: {
                ...action.config,
                codePage : updatedPageConfig
            }
        })
    };

    const handleUpdateNoCode = (updatedPageConfig) => {
        onUpdate({
            ...action,
            config: {
                ...action.config,
                noCodePage : updatedPageConfig
            }
        })
    };

    const handleUpdateDeactivated = (updatedPageConfig) => {
        onUpdate({
            ...action,
            config: {
                ...action.config,
                deactivatedPage : updatedPageConfig
            }
        })
    };
    
    return (
        <>
            <TabNavigation tabs={{
                "code" : "Code Page",
                "nocode" : "No Code Page",
                "deactivated" : "Deactivated Page"
            }} active="code" onChange={setTab}/>

            {tab === "code" && (
                <div className="mt-4">
                    <small className="form-text text-muted mt-2">
                        This page will be shown if a code is available.
                    </small>
                    <PageConfigForm className="mt-2" pageConfig={codePage} onUpdate={handleUpdateCode}/>
                    <div className="small text-muted mt-2">
                        Available Placeholders:<br/>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: '{{code}}'}}></span> - Code as text
                        </div>
                    </div>
                </div>
            )}
            {tab === "nocode" && (
                <div className="mt-4">
                    <small className="form-text text-muted">
                        This page will be shown if a the code list is empty or the limit is reached.
                    </small>
                    <PageConfigForm className="mt-2" pageConfig={noCodePage} onUpdate={handleUpdateNoCode}/>
                </div>
            )}
            {tab === "deactivated" && (
                <div className="mt-4">
                    <small className="form-text text-muted">
                        This page will be shown if the configuration is not active.
                    </small>
                    <PageConfigForm className="mt-2" pageConfig={deactivatedPage} onUpdate={handleUpdateDeactivated}/>
                </div>
            )}


        </>
    )
};

export default CodePageConfigEditPanel