import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';


const CopyButton = ({text, className, label}) => {
    const [copied, setCopied] = useState(false);

    return (
        <span className={className}>
            {copied ?
                <span className="text-success"><i className="far fa-check-circle mr-1" />Done!</span>
                :
                <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
                    <span>
                    <i className="far fa-copy mr-1 text-success" /><button className="btn-link border-0 p-0 bg-white">{label ? label : "Copy"}</button>
                    </span>
                </CopyToClipboard>
            }
        </span>
    )
};

export default CopyButton