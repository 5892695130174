import axios from "axios";

const triggerApi = {

    create(trigger) {
        return axios({
            indicatorId: 'create-trigger',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/triggers',
            data: trigger
        })
    },
    update(trigger) {
        return axios({
            indicatorId: 'update-trigger',
            method: 'put',
            url: process.env.REACT_APP_API_HOST + '/api/v1/triggers/' + trigger.id,
            data: trigger
        })
    },
    load(id) {
        return axios({
            indicatorId: 'load-trigger',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/triggers/' + id,
        })
    },
    delete(trigger) {
        return axios({
            indicatorId: 'delete-trigger',
            method: 'delete',
            url: process.env.REACT_APP_API_HOST + '/api/v1/triggers/' + trigger.id,
        })
    },
    find(configurationId) {
        return axios({
            indicatorId: 'load-trigger',
            method: 'get',
            url: process.env.REACT_APP_API_HOST + '/api/v1/triggers',
            params: {
                configurationId: configurationId
            }
        })
    }
};

export default triggerApi;