import axios from "axios";

const paymentApi = {
    createCheckoutSession(organizationId) {
        const url = process.env.REACT_APP_API_HOST + '/api/v1/payment/create-checkout-session';
        return axios({
            indicatorId: 'create-checkout-session',
            method: 'post',
            url: url,
            data: {organizationId: organizationId}
        })
    },

    createCustomerPortalSession(organizationId) {
        const url = process.env.REACT_APP_API_HOST + '/api/v1/payment/create-customer-portal-session';
        return axios({
            indicatorId: 'create-customer-portal-session',
            method: 'post',
            url: url,
            data: {organizationId: organizationId}
        })
    },

};

export default paymentApi;