import React from 'react';
import CopyButton from "../common/CopyButton";

const ChatFuelEndpointConfigInformation = ({trigger}) => {

    const url = "https://api.voucherjet.com/api/v1/p/chatfuel/"+trigger.id;
    return (
        <div>
            <div className="text-small">Callback URL:&nbsp;<span className="text-decoration-underline text-primary">{url}</span>
            <CopyButton className="ml-4" text={url} label="Copy"/>
            </div>
            <div className="mt-2 text-small"><i className="far fa-question-circle mr-1 text-success"></i>
                <a href="https://www.voucherjet.com/en/docs/how-to-distribute-unique-voucher-coupon-codes-with-chatfuel" rel="noreferrer noopener" target="_blank">How to connect your Chatfuel account with VoucherJet</a></div>
        </div>
    )
};

export default ChatFuelEndpointConfigInformation