import React from "react";
import FormError from "./FormError";
import "./Checkbox.scss";

// Checkbox input
export const CheckboxBootstrap = ({
                             field: { name, value, onChange, onBlur },
                             form: { errors, touched, setFieldValue },
                             id,
                             label,
                             hideError,
                             className,
                             ...props
                         }) => {
    return (
        <div className={className}>
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    name={name}
                    id={id}
                    value={value}
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...props}
                />
                <label className="custom-control-label" htmlFor={id}>
                    {label}
                </label>
                {!hideError && <FormError name={name} errors={errors} touched={touched}/>}
            </div>
        </div>
    );
};

// Checkbox input
export const Checkbox = ({
                      field: { name, value, onChange, onBlur },
                      form: { errors, touched, setFieldValue },
                      id,
                      label,
                      hideError,
                      className,
                      ...props
                  }) => {
    return (
        <div className={className}>
        <div>
            <label htmlFor={id}>
            <input
                name={name}
                id={id}
                type="checkbox"
                value={value}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                className={"form-check-input"}
            />
                <span className="label-icon position-absolute"/><span className="checkbox-label-text ml-3 d-inline-block">{label}</span>
            </label>
            {!hideError && <FormError name={name} errors={errors} touched={touched}/>}
        </div>
        </div>
    );
};

