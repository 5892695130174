import React, {useEffect, useState} from 'react';
import actionApi from "./actionApi";
import ActionListItem from "./ActionListItem";
import NewActionPanel from "./NewActionPanel";

const ActionSelectionPanel = ({accountId, configurationId}) => {

    const [actions, setActions] = useState([]);

    useEffect(() => {
        if(configurationId){
            actionApi
                .find(configurationId)
                .then((response) => {
                    setActions(response.data);
                })
        }
    }, [configurationId]);


    const handleUpdate = (action) => {
        actionApi
            .find(configurationId)
            .then((response) => {
                setActions(response.data);
            })
    };

    return (
        <div className="container mt-5">
            <div className="">
                <h5>Actions</h5>
                <span className="text-small text-muted">Initiated by the selected 'Trigger' multiple 'Actions' can be executed.</span>
            </div>
            {actions.map((item, key) =>
                <ActionListItem action={item} accountId={accountId} key={key} onUpdate={handleUpdate} onRemove={handleUpdate}/>
            )}

            {window.toggles.FT_ADD_ACTION && actions.length < 5 && (
                <div className="text-center mt-4">
                    <NewActionPanel configurationId={configurationId} className="" onCreate={handleUpdate}/>
                </div>
            )}

        </div>
    )

};

export default ActionSelectionPanel