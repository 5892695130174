import React from 'react';
import PrivateNavigation from "../navigation/PrivateNavigation";
import Footer from "../footer/Footer";
import Page from "../common/Page";
import {Link} from "react-router-dom";

const PaymentSuccessPage = () => {


    return <Page>
        <PrivateNavigation/>
        <section className="section">
            <div className="container mt-5">
                <h1>Thank You!</h1>
                <div className="mt-4">
                    Thanks for upgrading! You are now able to upload and distribute as many codes as you like.<br/>
                    <br/>
                    If you want to see or change billing details, cancel the subscription or download invoices please open the <Link to={'/organization/settings'}>Settings</Link> page.
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default PaymentSuccessPage