import React from 'react';
import ChatFuelResponseView from "./ChatFuelResponseView";
import ManyChatResponseView from "./ManyChatResponseView";

const ChatBotResponseConfigInformation = ({config, type}) => {

    const {
        codeResponse,
        noCodeResponse,
        deactivatedResponse,
    } = config;

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <div className="text-small text-muted">Response with code:</div>
                    {{
                        CHATFUEL_RESPONSE: <ChatFuelResponseView response={codeResponse}/>,
                        MANYCHAT_RESPONSE: <ManyChatResponseView response={codeResponse}/>,
                    }[type]}
                </div>
                <div className="col-4">
                    <div className="text-small text-muted">Response on empty code list/limit:</div>
                    {{
                        CHATFUEL_RESPONSE: <ChatFuelResponseView response={noCodeResponse}/>,
                        MANYCHAT_RESPONSE: <ManyChatResponseView response={noCodeResponse}/>,
                    }[type]}
                </div>
                <div className="col-4">
                    <div className="text-small text-muted">Response on deactivated configuration:</div>
                    {{
                        CHATFUEL_RESPONSE: <ChatFuelResponseView response={deactivatedResponse}/>,
                        MANYCHAT_RESPONSE: <ManyChatResponseView response={deactivatedResponse}/>,
                    }[type]}
                </div>
            </div>
        </div>
    )
};

export default ChatBotResponseConfigInformation