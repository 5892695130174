import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import LoadingButton from "../form/LoadingButton";
import userApi from "./userApi";
import * as Yup from "yup";
import {Link} from "react-router-dom";

const UserProfileForm = ({userId}) => {

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    });
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmedPassword: '',
    });
    const [passwordError, setPasswordError] = useState(false);
    const [passwordEditMode, setPasswordEditMode] = useState(false);


    useEffect(() => {
        userApi
            .load(userId)
            .then((response) => {
                setUser(response.data)
            })
    }, [userId]);


    const handleTogglePasswordEditMode = () => {
        setPasswordEditMode(!passwordEditMode)
    };

    const handleProfileSave = (form) => {
        userApi
            .update(user.id, user.email, form.firstName, form.lastName)
            .then((response) => {
                setUser(response.data)
            })
    };

    const handlePasswordUpdate = (form) => {
        userApi
            .updatePassword(user.id, form.currentPassword, form.newPassword)
            .then(() => {
                setPasswords({
                    currentPassword: '',
                    newPassword: '',
                    confirmedPassword: '',
                })
            })
            .catch(() => {
                setPasswordError(true)
            })
    };

    const validationSchemaProfile = Yup.object().shape({
        firstName: Yup.string()
            .required('This field is required.'),
        lastName: Yup.string()
            .required('This field is required.')
    });

    const validationSchemaPassword = Yup.object().shape({
        currentPassword: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.'),
        newPassword: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.'),
        confirmedPassword: Yup.string()
            .min(8, 'The password is too short')
            .required('This field is required.')
            .test('password-match' , 'Confirmation password does not match', function (value) {
                return this.parent.newPassword === value;
            })
    });

    return (
        <div>
            <h5 className="">Basic Information</h5>
            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchemaProfile}
                initialValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                }}
                onSubmit={handleProfileSave}
            >
                { props => (
                    <Form>
                        <div>
                            <div className="form-group">
                                <label htmlFor="firstName">Login</label>
                                <input type="text" readOnly className="form-control-plaintext" id="staticEmail"
                                       defaultValue={user.email}/>

                            </div>
                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <Field
                                    name="firstName"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <Field
                                    name="lastName"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    component={TextInput}/>
                            </div>
                            <div className="form-group">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-user"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <h5 className="">Change Password</h5>
            {!passwordEditMode && (
                <div>
                    <Link to="#" className="btn btn-link text-small pl-0" onClick={handleTogglePasswordEditMode}>Set new password...</Link>
                </div>
            )}
            {passwordEditMode && (
                <div>
                    {passwordError && (
                        <div className="alert alert-danger text-small" role="alert">
                            Please check username and password.
                        </div>
                    )}
                    <Formik
                        enableReinitialize={true}
                        validateOnBlur={true}
                        validationSchema={validationSchemaPassword}
                        initialValues={{
                            currentPassword: passwords.currentPassword,
                            newPassword: passwords.newPassword,
                            confirmedPassword : passwords.confirmedPassword
                        }}
                        onSubmit={handlePasswordUpdate}
                    >
                        { props => (
                            <Form>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="currentPassword">Current password</label>
                                        <Field
                                            name="currentPassword"
                                            type="password"
                                            className="form-control"
                                            placeholder=""
                                            component={TextInput}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New password</label>
                                        <Field
                                            name="newPassword"
                                            type="password"
                                            className="form-control"
                                            placeholder=""
                                            component={TextInput}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmedPassword">Confirm new password</label>
                                        <Field
                                            name="confirmedPassword"
                                            type="password"
                                            className="form-control"
                                            placeholder=""
                                            component={TextInput}/>
                                    </div>

                                    <div className="form-group">
                                        <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                                       indicatorId="update-password"
                                                       label="Change Password"/>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}

        </div>
    )
};

export default UserProfileForm