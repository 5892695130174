import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ConfigurationStateBadge from "./ConfigurationStateBadge";
import {CodeListTypeNames, TriggerType} from "./configuration";
import Count from "./Count";
import TriggerLabel from "./TriggerLabel";
import ActionLabel from "./ActionLabel";
import ModalConfirmation from "../modal/ModalConfirmation";
import AmzleadInfo from "../trigger/amzlead/AmzleadInfo";

const ConfigurationListItem = ({configuration, onRemove}) => {

    const [modalVisible, setModalVisible] = useState(false);

    const hasTrigger = configuration.trigger !== undefined;
    const hasActions = configuration.actions.length > 0;
    const codeList = configuration.codeList;
    const hasCodes = codeList !== undefined;
    const isAmzlead = configuration.trigger === TriggerType.LANDINGPAGE_AMZLEAD_LINK || configuration.trigger === TriggerType.LANDINGPAGE_AMZLEAD_FORM

    const handleToggleModalVisible = () => {
        setModalVisible(!modalVisible)
    };

    return (
        <div className="border rounded p-4 mt-4">

            <ModalConfirmation
                isOpen={modalVisible}
                onSubmit={() => onRemove(configuration)}
                onClose={handleToggleModalVisible}
                buttonLabel="Delete"
                buttonClassName="btn-danger"
                title="Delete Configuration">
                <p>Delete '{configuration.name}' configuration?</p>
            </ModalConfirmation>


            <div>
                <Link  to={"/configurations/" + configuration.id}><h4 className="text-dark font-weight-normal hover:underline-jv-link d-inline-block">{configuration.name}</h4></Link>
            </div>
            {isAmzlead && (
                <div>
                <AmzleadInfo/>
                </div>
            )}
            <div className="d-flex justify-content-between">
                <div>
                    <ConfigurationStateBadge state={configuration.state}/><br/>
                </div>
                <div>
                    <Link to={"/configurations/" + configuration.id}
                          className="ml-1 btn btn-sm btn-outline-secondary">Edit</Link>
                    <button onClick={handleToggleModalVisible}
                            className="ml-1 btn btn-sm btn-outline-secondary">Delete</button>
                </div>
            </div>
            {hasTrigger && (
                <div className="text-small d-flex align-items-center h-100">
                    <TriggerLabel type={configuration.trigger}/>
                    <div className="mr-1 ml-1">
                        will trigger
                    </div>

                    {hasActions && (
                        configuration.actions.map((item, key) =>
                            <ActionLabel key={key} type={item}/>
                        )
                    )}
                    {!hasActions && (
                        <div className="">
                            <b>'Nothing'</b>
                        </div>
                    )}


                </div>
            )}
            {!hasTrigger && (
                <div className="text-small">
                    No trigger defined yet.
                </div>
            )}
            {hasCodes && (
                <div className="d-flex justify-content-between mt-2 text-small">
                    <div className="text-dark w-25"><b>{CodeListTypeNames[codeList.type]}: {codeList.name}</b></div>
                    <div className="text-muted text-uppercase">Delivered: <b><Count value={codeList.delivered}/></b></div>
                    <div className="text-muted text-uppercase">Viewed: <b><Count value={codeList.viewed}/></b></div>
                    <div className="text-muted text-uppercase">Used: <b><Count value={codeList.used}/></b></div>
                </div>
            )}
            {!hasCodes && (
                <div className="text-small">
                    No codes defined yet.
                </div>
            )}
        </div>
    )
};

export default ConfigurationListItem