import React from "react";

const ConfigurationStateBadge = ({state}, {className}) => {

    const colors = {
        ACTIVE: '#00b473',
        DEACTIVATED: '#ff3809'
    };
    const label = state ? state : "";
    const color = colors[state];
    return (
        <span className={"badge text-white " +className} style={{"backgroundColor": color}}>{label}</span>
    )
};

export default ConfigurationStateBadge;