import React from 'react';
import "react-table/react-table.css";
import VoucherFormForm from "./VoucherFormForm";

const VoucherFormConfigEditPanel = ({
                               triggerId,
                               trigger,
                               onUpdate = () => {
                               }
                           }) => {

    const voucherFormConfig = trigger.config;

    const handleUpdateTrigger = (voucherFormConfig) => {
        onUpdate({
            ...trigger,
            config: voucherFormConfig
        })
    };

    return (
        <VoucherFormForm voucherFormConfig={voucherFormConfig} onUpdate={handleUpdateTrigger}/>
    )
};

export default VoucherFormConfigEditPanel