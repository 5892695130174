import React, {useEffect, useState} from 'react';
import triggerApi from "./triggerApi";
import TriggerListItem from "./TriggerListItem";
import NewTriggerPanel from "./NewTriggerPanel";

const TriggerSelectionPanel = ({accountId, configurationId}) => {

    const [triggers, setTriggers] = useState([]);

    useEffect(() => {
        if(configurationId){
            triggerApi
                .find(configurationId)
                .then((response) => {
                    setTriggers(response.data);
                })
        }
    }, [configurationId]);

    const handleUpdate = (trigger) => {
        triggerApi
            .find(configurationId)
            .then((response) => {
                setTriggers(response.data);
            })
    };

    return (
        <div className="container mt-5">
            <div className="">
                <h5>Trigger</h5>
                <span className="text-small text-muted">A 'Trigger' creates an event that initiates the code distribution.</span>
            </div>
            {triggers.map((item, key) =>
                <TriggerListItem trigger={item} accountId={accountId} key={item.id} onUpdate={handleUpdate} onRemove={handleUpdate}/>
            )}

            {triggers.length === 0 && (
                <div className="text-center mt-4">
                    <NewTriggerPanel configurationId={configurationId} className="" onCreate={handleUpdate}/>
                </div>
            )}
        </div>
    )

};

export default TriggerSelectionPanel