import React from "react";


const ManyChatResponseView = ({response}) => {
    return (
        response.content.messages.map((item, index) =>
            <div key={index} className="small text-dark mt-2 bg-loading border border-1 rounded-4 p-3">
                {item.text}<br/>
            </div>
        )
    )
};

export default ManyChatResponseView