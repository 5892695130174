
export const ConfigurationState = Object.freeze({
    ACTIVE: "ACTIVE",
    DEACTIVATED: "DEACTIVATED"
});

export const TriggerType = Object.freeze({
    MANYCHAT_ENDPOINT : "MANYCHAT_ENDPOINT",
    MANYCHAT_APP_ENDPOINT : "MANYCHAT_APP_ENDPOINT",
    CHATFUEL_ENDPOINT : "CHATFUEL_ENDPOINT",
    KLICKTIPP_ENDPOINT : "KLICKTIPP_ENDPOINT",
    VOUCHER_FORM : "VOUCHER_FORM",
    VOUCHER_LINK : "VOUCHER_LINK",
    LANDINGPAGE_AMZLEAD_FORM : "LANDINGPAGE_AMZLEAD_FORM",
    LANDINGPAGE_AMZLEAD_LINK : "LANDINGPAGE_AMZLEAD_LINK"
});

export const TriggerTypeNames = {
    MANYCHAT_ENDPOINT : "ManyChat Endpoint",
    MANYCHAT_APP_ENDPOINT : "ManyChat App Endpoint",
    CHATFUEL_ENDPOINT : "Chatfuel Endpoint",
    KLICKTIPP_ENDPOINT : "KlickTipp Endpoint",
    VOUCHER_FORM : "Code Pop-Up/Form",
    VOUCHER_LINK : "Code Link",
    LANDINGPAGE_AMZLEAD_FORM : "AMZLEAD Landing Page (Code Mail)",
    LANDINGPAGE_AMZLEAD_LINK : "AMZLEAD Landing Page (Code Link)"
};

export const ActionType = Object.freeze({
    MANYCHAT_RESPONSE : "MANYCHAT_RESPONSE",
    MANYCHAT_APP_RESPONSE : "MANYCHAT_APP_RESPONSE",
    CHATFUEL_RESPONSE : "CHATFUEL_RESPONSE",
    CODE_MAIL : "CODE_MAIL",
    CODE_PAGE : "CODE_PAGE"
});

export const ActionTypeNames = {
    CHATFUEL_RESPONSE : "Chatfuel Response",
    MANYCHAT_RESPONSE : "ManyChat Response",
    MANYCHAT_APP_RESPONSE : "ManyChat App Response",
    CODE_MAIL : "Code Mail",
    CODE_PAGE : "Code Page"
};

export const CodeListTypeNames = {
    CUSTOM : "Custom Codes List"
};
