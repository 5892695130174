import React from 'react';

const PlanPanel = ({plan}) => {

    return (
        <div>
            {plan === 'PLAN_FREE' && (
                <p>Your are currently running on our <b>'Free Plan'</b> and will not be charged.</p>
            )}
            {plan !== 'PLAN_FREE' && (
                <p>You have upgraded to a paid plan and will be charged based on usage.</p>
            )}
        </div>
    )
};

export default PlanPanel