import React, { useState } from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import LoadingButton from "../form/LoadingButton";
import TextInput from "../form/TextInput";
import authApi from "./authApi";
import PublicNavigation from "../navigation/PublicNavigation";
import Footer from "../footer/Footer";
import Page from "../common/Page";

const RequestPasswordPage = () => {

    const [authError, setAuthError] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);


    const handleReset = (values) => {
        setAuthError(false);
        setAuthSuccess(false);
        authApi
            .passwordRequest(values.email, values.password)
            .then(() => {
                setAuthSuccess(true)
            })
            .catch(() => {
                setAuthError(true)
            })
    };


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email.')
            .required('This field is required.'),
    });

    return <Page>
        <PublicNavigation/>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="container container--mini mt-5">
                        <h1 className="mb-5 text-center">Reset Password</h1>

                        {authError && (
                            <div className="alert alert-danger text-small" role="alert">
                                The user was not found.
                            </div>
                        )}


                        {authSuccess && (
                            <div className="alert alert-success text-small" role="alert">
                                Please check your email inbox and follow the instructions.
                            </div>
                        )}

                        <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validationSchema={validationSchema}
                            initialValues={{
                                email: ''
                            }}
                            onSubmit={handleReset}
                        >
                            { props => (
                                <Form>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    component={TextInput}/>
                                            </div>
                                            <div className="form-group">
                                                <LoadingButton className="btn btn-outline-success btn-block mb-4 mt-5"
                                                               indicatorId="password-request"
                                                               label="Request Password"/>
                                            </div>
                                        </div>
                                </Form>
                            )}
                        </Formik>



                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </Page>
};

export default RequestPasswordPage