import axios from 'axios/index'
import auth from "./auth";


const authApi = {
    signup(firstName, lastName, consent, email, password) {
        return axios({
            indicatorId: 'signup',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/auth/signup',
            data: {
                firstName: firstName,
                lastName: lastName,
                consent: consent,
                email: email,
                password: password
            }
        })
    },
    passwordRequest(email) {
        return axios({
            indicatorId: 'password-request',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/auth/password/request',
            data: {
                email: email
            }
        })
    },
    passwordReset(token, newPassword) {
        return axios({
            indicatorId: 'password-reset',
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/auth/password/reset',
            data: {
                token: token,
                newPassword: newPassword
            }
        })
    },
    login(email, password) {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/auth/login',
            withCredentials: true,
            indicatorId: 'login',
            data: {
                email: email,
                password: password
            },
            transformResponse: [function (data) {
                let response = JSON.parse(data);
                if (response.token) {
                    auth.authenticate(
                        email,
                        response.userId,
                        response.organizationId,
                        response.token,
                        response.accountMode,
                        response.accountId
                    );
                }
                return response;
            }]
        })
    },
    logout() {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_HOST + '/api/v1/auth/logout',
            withCredentials: true,
            data : {}
        }).then(() => {
            auth.signout();
            window.location.href = "/login"
        }).catch(() => {
            auth.signout();
        })
    }
};




export default authApi;