import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './Modal.css'
import Modal from "./Modal";
import LoadingButton from "../form/LoadingButton";

export default class ModalConfirmation extends Component {

    static defaultProps = {
        isOpen: false,
        additionalClassName: '',
        buttonClassName: "btn-success",
        buttonLabel: 'ButtonLabel',
        indicatorId: 'not-set',
        title: ''
    };

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        isOpen: PropTypes.bool,
        title: PropTypes.string,
        buttonClassName: PropTypes.string,
        buttonLabel: PropTypes.string,
        indicatorId: PropTypes.string,
        additionalClassNamePrefix: PropTypes.string
    };

    render() {
        const {
            isOpen, onClose, title, buttonLabel, onSubmit, indicatorId,
            children,
            additionalClassNamePrefix,
            buttonClassName
        } = this.props;
        return (
            <Modal isOpen={isOpen} onClose={onClose} additionalClassNamePrefix={additionalClassNamePrefix}>
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    <LoadingButton className={buttonClassName}
                                   indicatorId={indicatorId}
                                   label={buttonLabel}
                                   onClick={onSubmit}/>
                    <button type="button" className="btn btn-light" onClick={onClose}>Close</button>
                </div>
            </Modal>
        )
    }
}
