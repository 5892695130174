import React, {useState} from 'react';
import "react-table/react-table.css";
import * as Yup from "yup";
import LoadingButton from "../form/LoadingButton";
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import HtmlEditor from "./HtmlEditor";
import {Link} from "react-router-dom";

const CodeMailConfigEditPanel = ({
                               actionId,
                               action,
                               onUpdate = () => {
                               }
                           }) => {


    const {subject, body} = action.config;
    const [updatedBody, setUpdatedBody] = useState(body);

    const handleUpdate = (form) => {
        const config = {
            subject: form.subject,
            body: updatedBody
        };
        onUpdate({
            ...action,
            config: config
        })
    };


    const validationSchemaProfile = Yup.object().shape({
        subject: Yup.string().required('This field is required')
    });

    return (
        <>
            <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={validationSchemaProfile}
                initialValues={{
                    subject: subject
                }}
                onSubmit={handleUpdate}
            >
                {props => (
                    <Form>
                        <div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Email Subject:</label>
                                    <Field
                                        name="subject"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        component={TextInput}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Email Content:</label>
                                    <HtmlEditor html={body} onChange={setUpdatedBody}/>
                                </div>
                            </div>

                            <div className="small text-muted mt-2">
                                Available Placeholders:<br/>
                                <div>
                                    <span dangerouslySetInnerHTML={{__html: '{{code}}'}}></span> - Code as text
                                </div>
                                <div>
                                    <span dangerouslySetInnerHTML={{__html: '{{name}}'}}></span> - Name of the recipient
                                </div>
                                <div>
                                    <span dangerouslySetInnerHTML={{__html: '{{sender_address}}'}}></span> - Address/HTML as
                                    defined in the <Link to="/organization/settings#account">Settings > Account</Link>
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                               indicatorId="update-action"
                                               label="Update"/>
                            </div>
                        </div>
                    </Form>

                )}

            </Formik>
        </>

    )
};

export default CodeMailConfigEditPanel