import React from 'react';
import {Link} from "react-router-dom";

const UsageExceededPanel = ({limit}) => {

    return (
        <div className="mt-2 alert alert-danger text-small" role="alert">
            You have exceeded the upload limit of {limit} codes and will no longer be able to upload any new codes.<br/>
            <br/>
            Please change to our paid plan to if you want to use the service and support our team.
            Thanks!<br/>

            <div className={"mt-2 bg-white d-inline-block"}>
                <Link to={'/payment/upgrade'} className="btn btn-sm btn-outline-success">Show Paid Plan</Link>
            </div>
        </div>
    )
};

export default UsageExceededPanel