import React, {useEffect, useState} from 'react';
import "react-table/react-table.css";
import codeListApi from "./codeListApi";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import TextInput from "../form/TextInput";
import LoadingButton from "../form/LoadingButton";
import Count from "../configuration/Count";
import {CheckboxBootstrap} from "../form/CheckBox";
import FormError from "../form/FormError";
import DataTable from "../form/DataTable";
import CodeListLabel from "./CodeListLabel";
import Files from 'react-files'
import ModalConfirmation from "../modal/ModalConfirmation";
import UsageExceededPanel from "../organization/UsageExceededPanel";

const CodeListEditPanel = ({
                               codeListId,
                               onUpdate = () => {
                               },
                               uploadOnly = false
                           }) => {

    const [limitError, setLimitError] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [codeList, setCodeList] = useState({});
    const [enableLimitPanel, setEnableLimitPanel] = useState(false);
    const [codesInput, setCodesInput] = useState("");
    const [selectedCodeIds, setSelectedCodeIds] = useState([]);
    const [newCodes, setNewCodes] = useState([]);
    const [codesPage, setCodesPage] = useState({
        content: [],
        totalPages: 0,
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const defaultSorted = {
        dataField: 'delivered',
        order: 'desc'
    };
    const handleToggleEnableLimitPanel = () => {
        setEnableLimitPanel(!enableLimitPanel)
    };

    // load selected on id change
    useEffect(() => {
        if (codeListId) {
            codeListApi
                .load(codeListId)
                .then((response) => {
                    const cl = response.data;
                    setCodeList(cl);
                    setEnableLimitPanel(cl.limitDistribution)
                })
        }
    }, [codeListId]);

    useEffect(() => {
        if (codeListId) {
            //loadCodes(codeListId, "created", 1, 20)
        }
    }, [codeListId]);

    const loadCodes = (id, sort, page, size) => {
        codeListApi
            .loadCodes(id, sort, page, size)
            .then((response) => {

                setCodesPage(response.data)
            })
    };

    const handleUpdate = (form) => {
        codeListApi
            .update({
                ...codeList,
                name: form.name,
                limit: form.limit,
                limitDistribution: form.limitDistribution,
                limitPerUser: form.limitPerUser
            })
            .then((response) => {
                const cl = response.data;
                setCodeList(cl);
                onUpdate(cl);
            })
    };

    const handleAddCodes = () => {
        setLimitError(false);
        codeListApi
            .addCodes(codeList.id, newCodes)
            .then((response) => {
                const cl = response.data;
                setCodesInput("");
                setNewCodes([]);
                setCodeList(cl);
                onUpdate(cl);
                loadCodes(codeListId, defaultSorted.dataField + "," + defaultSorted.order, 1, 20)
            })
            .catch((error) => {
                let data = error.response.data;
                if (data.status === 429) {
                    setLimitError(true);
                }
            })
    };

    const handleDeleteCodes = () => {
        codeListApi
            .deleteCodes(codeList.id, selectedCodeIds)
            .then((response) => {
                const cl = response.data;
                setSelectedCodeIds([]);
                setCodeList(cl);
                onUpdate(cl);
                loadCodes(codeListId, defaultSorted.dataField + "," + defaultSorted.order, 1, 20);
                setDeleteModalVisible(false);
            })
    };

    const FileDownload = require('js-file-download');
    const handleExportContacts = () => {
        codeListApi
            .exportContacts(codeList.id)
            .then((response) => {
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader];
                const effectiveFileName = (suggestedFileName === undefined
                    ? "codes_delivered_utf8.csv"
                    : suggestedFileName);
                FileDownload(response.data, effectiveFileName);
            })
    };


    const fileReader = new FileReader();

    fileReader.onload = (event) => {
        const fileContent = event.target.result;
        console.log("fileContent: ", fileContent);
        handleCodeInputChange({target: {value: fileContent}});
    };

    const onFilesChange = (files) => {
        fileReader.readAsText(files[0]);
    };

    const onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    };

    const handleCodeInputChange = (e) => {
        const str = e.target.value;
        setCodesInput(str);
        const codes = str.split(/\r?\n/g).filter((s) => {
            return s
        });
        setNewCodes(codes);
    };

    const handleOnSelectChange = (rows) => {
        setSelectedCodeIds(rows);
    };

    const handleToggleModalVisible = () => {
        setDeleteModalVisible(!deleteModalVisible)
    };

    const validationSchemaProfile = Yup.object().shape({
        name: Yup.string()
            .required('This field is required'),
        limit: Yup.number()
            .typeError('Limit must be a number')
            .positive('Limit must be greater than zero')
            .required('This field is required')
    });

    const loading = codeList.id === undefined;
    const {
        type,
        size,
        delivered,
        limitDistribution,
        limit,
        deliveredInLimit,
        viewed,
        used
    } = codeList;
    const remainingInLimit = limit - deliveredInLimit;

    const onTableChange = (type, {sortField, sortOrder, data, page, sizePerPage}) => {
        loadCodes(codeListId, sortField + "," + sortOrder, page, sizePerPage)
    };

    return (
        <>

            <ModalConfirmation
                isOpen={deleteModalVisible}
                onSubmit={handleDeleteCodes}
                onClose={handleToggleModalVisible}
                buttonLabel="Delete"
                buttonClassName="btn-danger"
                title="Delete Codes">
                <p>Sure you want to delete {selectedCodeIds.length} codes and its contacts?</p>
            </ModalConfirmation>


            {!loading && (
                <div>
                    <div className="mb-2">
                        <CodeListLabel type={type}/>
                    </div>

                    {!uploadOnly && (
                        <div>
                            <div className="d-flex justify-content-between text-small">
                                <div>
                                    <div className="text-muted">{size - delivered} of {size} codes remaining in total.
                                    </div>
                                    {limitDistribution && (remainingInLimit > 0) && (
                                        <div className="text-muted">{remainingInLimit} of {limit} codes remaining in
                                            daily limit.</div>
                                    )}
                                    {limitDistribution && (remainingInLimit <= 0) && (
                                        <div className="text-warning">Daily limit of {limit} is reached.</div>
                                    )}
                                </div>
                                <div className="text-muted text-uppercase">Size: <b><Count value={size}/></b></div>
                                <div className="text-muted text-uppercase">Delivered: <b><Count value={delivered}/></b>
                                </div>
                                <div className="text-muted text-uppercase">Viewed: <b><Count value={viewed}/></b></div>
                                <div className="text-muted text-uppercase">Used: <b><Count value={used}/></b></div>
                            </div>
                            <div className="border-top mt-4"></div>

                            <div className="mt-3">
                                <h5>Settings</h5>
                                <Formik
                                    enableReinitialize={true}
                                    validateOnBlur={true}
                                    validationSchema={validationSchemaProfile}
                                    initialValues={{
                                        name: codeList.name,
                                        limit: codeList.limit,
                                        limitDistribution: codeList.limitDistribution,
                                        limitPerUser: codeList.limitPerUser
                                    }}
                                    onSubmit={handleUpdate}
                                >
                                    {props => (
                                        <Form>
                                            <div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>Internal Name</label>
                                                        <Field
                                                            name="name"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            component={TextInput}/>
                                                        <small className="form-text text-muted">
                                                            This name will be not visible to the end user.
                                                        </small>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>LIMIT PER USER</label>
                                                        <div
                                                            className="d-flex justify-content-start align-items-center">
                                                            <Field
                                                                className="font-weight-bold"
                                                                component={CheckboxBootstrap}
                                                                name="limitPerUser"
                                                                id="limitPerUser"
                                                            />
                                                            <div>Deliver only 1 code per user.</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>DAILY LIMIT</label>
                                                        <div
                                                            className="d-flex justify-content-start align-items-center">
                                                            <Field
                                                                className="font-weight-bold"
                                                                component={CheckboxBootstrap}
                                                                name="limitDistribution"
                                                                id="limitDistribution"
                                                                onClick={handleToggleEnableLimitPanel}
                                                            />
                                                            <div>This list is limited to</div>
                                                            <div className="ml-2 mr-2">
                                                                <Field
                                                                    disabled={!enableLimitPanel}
                                                                    hideError={true}
                                                                    name="limit"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    size="4"
                                                                    component={TextInput}/>
                                                            </div>
                                                            <div>codes per day.</div>
                                                        </div>
                                                        <FormError name="limit" errors={props.errors}
                                                                   touched={props.touched}/>
                                                    </div>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <LoadingButton className="btn btn-outline-success btn-sm mb-4"
                                                                   indicatorId="update-codelist"
                                                                   label="Update"/>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    )}

                    <div>
                        <div className="d-flex justify-content-between">
                            <h5>Codes</h5>
                            <div>
                                <LoadingButton
                                    disabled={selectedCodeIds.length === 0}
                                    className="btn btn btn-outline-secondary btn-sm mb-4 ml-2"
                                    onClick={handleToggleModalVisible}
                                    label="Delete Codes"
                                />
                                <LoadingButton
                                    className="btn btn btn-outline-secondary btn-sm mb-4 ml-2"
                                    onClick={handleExportContacts}
                                    indicatorId="export-codelist-contacts"
                                    label="Export Contacts"
                                />
                            </div>

                        </div>
                        <div className="mt-3">
                            <DataTable
                                page={codesPage.number + 1}
                                totalSize={codesPage.totalElements}
                                content={codesPage.content}
                                columns={[
                                    {
                                        text: "Code",
                                        dataField: "code",
                                        sort: true,
                                    },
                                    {
                                        text: "Delivered",
                                        dataField: "delivered",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return cell && (<span>{new Date(cell).toLocaleString()}</span>)
                                        }
                                    },
                                    {
                                        text: "Contact ID",
                                        dataField: "contactId",
                                    },
                                    {
                                        text: "Contact Name",
                                        dataField: "contactName",
                                    },
                                    {
                                        text: "Trigger",
                                        dataField: "triggerType",
                                    }
                                ]}
                                defaultSorted={[defaultSorted]}
                                onTableChange={onTableChange}
                                onSelectChange={handleOnSelectChange}
                                selectedIds={selectedCodeIds}
                            />
                        </div>
                        <div className="form-group">
                            <label>NEW CODES</label>
                            <textarea
                                name="name"
                                type="text"
                                value={codesInput}
                                className="form-control"
                                placeholder=""
                                rows={5}
                                onChange={handleCodeInputChange}
                            />
                            <small className="form-text text-muted">
                                Copy &amp; paste new codes here or "Upload" a text file having one code per
                                line. <b>{newCodes.length}</b> codes detected.<br/>
                            </small>
                        </div>
                        <div className="form-group mt-2 d-flex justify-content-start">
                            <div>
                                <LoadingButton className="btn btn-outline-success btn-sm"
                                               indicatorId="add-codelist-codes"
                                               onClick={handleAddCodes}
                                               disabled={newCodes.length === 0}
                                               label={"Add " + newCodes.length + " Codes"}/>
                            </div>
                            <div>
                                <Files
                                    className='files-dropzone d-inline-block'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['.txt']}
                                    multiple={false}
                                    maxFiles={1}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable={true}
                                >
                                    <LoadingButton className="btn btn-outline-secondary btn-sm ml-4"
                                                   indicatorId="add-codelist-codes"
                                                   onClick={() => {
                                                   }}
                                                   label="Upload"/>
                                </Files>
                            </div>


                        </div>
                        {limitError && (
                            <div>
                                <UsageExceededPanel limit={100}/>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {loading && (
                <div>
                    <div className="mt-5">
                        <h1 className="d-block w-50 bg-loading rounded">&nbsp;</h1>
                        <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                        <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                        <span className="d-block w-25 bg-loading rounded mt-1">&nbsp;</span>
                    </div>
                    <div className="mt-4">
                        <p className="bg-loading rounded w-100 d-inline-block">
                            &nbsp;<br/>
                            &nbsp;<br/>
                            &nbsp;<br/>
                            &nbsp;<br/>
                            &nbsp;<br/>
                            &nbsp;<br/>
                        </p>
                    </div>
                </div>
            )}
        </>

    )
};

export default CodeListEditPanel